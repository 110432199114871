import jwtDecode from "jwt-decode";

// see https://stackoverflow.com/questions/2013255/how-to-get-year-month-day-from-a-date-object
export const getDateFromString = (x: string): string => {
  const o = new Date(x);
  const y = String(o.getUTCFullYear());
  const m =
    (String(o.getUTCMonth() + 1).length === 1 ? "0" : "") +
    String(o.getUTCMonth() + 1);
  const d =
    (String(o.getUTCDate()).length === 1 ? "0" : "") + String(o.getUTCDate());
  return `${y}-${m}-${d}`;
};

export const decodeToken = (accessToken: string): string => {
  interface TokenDto {
    username: string;
  }
  return jwtDecode<TokenDto>(accessToken).username;
};

export const getUsername = (): string => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken !== null) {
    return decodeToken(accessToken);
  }
  return "";
};

// see http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
export const generateQuickGuid = (): string =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15); // eslint-disable-line max-len
