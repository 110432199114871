import { Coda as CodaSymbol, Segno } from "../../svg/symbols";

export type Props = {
  symbol: number;
};

const Coda = (props: Props) => (
  <div className="annot symbol">
    {(() => {
      switch (props.symbol) {
        case 1:
          return <CodaSymbol />;
        case 2:
          return <Segno />;
        default:
          return null;
      }
    })()}
  </div>
);

export default Coda;
