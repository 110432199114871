import {
  takeLatest,
  call,
  put,
  fork,
  CallEffect,
  PutEffect,
  ForkEffect
} from "redux-saga/effects";
import { message } from "antd";
import {
  loginAPI,
  initResetPasswordAPI,
  resetPasswordAPI,
  signupAPI,
  activateAccountAPI
} from "../../api/main";
import { setUser } from "../../util/sentry";
import { decodeToken } from "../../util";
import { captureException } from "../../util/sentry";
import * as actions from "./actions";
import * as constants from "./constants";
import * as types from "./types";

function* login({
  usernameOrEmail,
  password,
  navigate
}: types.LoginRequestedAction): Generator<
  | CallEffect<{ jwt: string }>
  | PutEffect<types.LoginSucceededAction>
  | PutEffect<types.LoginFailedAction>
> {
  try {
    const res: any = yield call(loginAPI, usernameOrEmail, password);
    if (!res.jwt && res.message) {
      message.info(res.message);
      return;
    }
    const username = decodeToken(res.jwt);
    yield put(actions.loginSucceeded(username));
    setUser({ username });
    localStorage.setItem("access_token", res.jwt);
    navigate("/");
  } catch (e) {
    yield put(actions.loginFailed());
    captureException(e);
  }
}

function* initResetPassword({
  email
}: types.ResetPasswordRequestedAction): Generator<
  CallEffect<{ message: string }> | PutEffect<types.ResetPasswordFailedAction>
> {
  try {
    const res: any = yield call(initResetPasswordAPI, email);
    if (res.message) {
      message.info(res.message);
    }
  } catch (e) {
    yield put(actions.initResetPasswordFailed());
    captureException(e);
  }
}

function* resetPassword({
  email,
  token,
  password,
  navigate,
}: types.ResetPasswordRequestedAction): Generator<
  | CallEffect<{ message: string }>
  | PutEffect<types.ResetPasswordFailedAction>
> {
  try {
    const res: any = yield call(resetPasswordAPI, email, token, password);
    if (res.message) {
      message.info(res.message);
      navigate("/login");
    }
  } catch (e) {
    yield put(actions.resetPasswordFailed());
    captureException(e);
  }
}

function* logout({
  navigate,
}: types.LogoutRequestedAction): Generator<
  PutEffect<types.LogoutSucceededAction>
> {
  localStorage.removeItem("access_token");
  yield put(actions.logoutSucceeded());
  navigate("/");
}

function* signup({
  username,
  email,
  password,
  grecaptcha
}: types.SignupRequestedAction): Generator<
  CallEffect<{ message: string }> | PutEffect<types.SignupFailedAction>
> {
  try {
    const res: any = yield call(
      signupAPI,
      username,
      email,
      password,
      grecaptcha
    );
    message.info(res.message);
  } catch (e) {
    yield put(actions.signupFailed());
    captureException(e);
  }
}

function* activateAccount({
  username,
  token,
  navigate
}: types.ActivateAccountRequestedAction): Generator<
  | CallEffect<{ jwt: string }>
  | PutEffect<types.ActivateAccountSucceededAction>
  | PutEffect<types.LoginSucceededAction>
  | PutEffect<types.ActivateAccountFailedAction>
> {
  try {
    const { jwt }: any = yield call(activateAccountAPI, username, token);
    yield put(actions.activateAccountSucceeded(decodeToken(jwt)));
    yield put(actions.loginSucceeded(username));
    setUser({ username });
    localStorage.setItem("access_token", jwt);
    navigate("/");
    message.success("Successfully activated account!");
  } catch (e) {
    yield put(actions.activateAccountFailed());
    captureException(e);
  }
}

function* loginSaga(): Generator<ForkEffect<types.LoginRequestedAction>> {
  yield takeLatest(constants.LOGIN_REQUESTED, login);
}
function* initResetPasswordSaga(): Generator<
  ForkEffect<types.InitResetPasswordRequestedAction>
> {
  yield takeLatest(constants.INITRESETPASSWORD_REQUESTED, initResetPassword);
}
function* resetPasswordSaga(): Generator<
  ForkEffect<types.ResetPasswordRequestedAction>
> {
  yield takeLatest(constants.RESETPASSWORD_REQUESTED, resetPassword);
}
function* logoutSaga(): Generator<ForkEffect<types.LogoutRequestedAction>> {
  yield takeLatest(constants.LOGOUT_REQUESTED, logout);
}
function* signupSaga(): Generator<ForkEffect<types.SignupRequestedAction>> {
  yield takeLatest(constants.SIGNUP_REQUESTED, signup);
}
function* activateAccountSaga(): Generator<
  ForkEffect<types.ActivateAccountRequestedAction>
> {
  yield takeLatest(constants.ACTIVATEACCOUNT_REQUESTED, activateAccount);
}

const a = [
  fork(loginSaga),
  fork(initResetPasswordSaga),
  fork(resetPasswordSaga),
  fork(logoutSaga),
  fork(signupSaga),
  fork(activateAccountSaga)
];
export default a
