import React, { ReactNode } from "react";

export interface Props {
  children: ReactNode;
}

export const Toolbar = ({ children }: Props) => {
  const draggable = window.innerWidth > 769;
  const toolbarRef = React.createRef<HTMLDivElement>();
  return (
    <div
      id="toolbar"
      ref={toolbarRef}
      onMouseDown={(e: React.MouseEvent): void => {
        if (!toolbarRef.current || !draggable) {
          return;
        }
        let [dx, dy, currX, currY]: number[] = [0, 0, e.clientX, e.clientY];
        document.onmouseup = (): void => {
          document.onmouseup = null;
          document.onmousemove = null;
        };
        document.onmousemove = (ev: MouseEvent): void => {
          if (!toolbarRef.current) {
            return;
          }
          dx = currX - ev.clientX;
          dy = currY - ev.clientY;
          currX = ev.clientX;
          currY = ev.clientY;
          toolbarRef.current.style.left = `${toolbarRef.current.offsetLeft -
            dx}px`;
          toolbarRef.current.style.top = `${toolbarRef.current.offsetTop -
            dy}px`;
        };
      }}
      onTouchStart={(e: React.TouchEvent): void => {
        if (!toolbarRef.current || !draggable) {
          return;
        }
        if (e.changedTouches.length === 0) {
          return;
        }
        let [dx, dy, currX, currY]: number[] = [
          0,
          0,
          e.changedTouches[0].pageX,
          e.changedTouches[0].pageY
        ];
        document.ontouchend = (): void => {
          document.ontouchend = null;
          document.ontouchmove = null;
        };
        document.ontouchmove = (ev: TouchEvent): void => {
          if (!toolbarRef.current) {
            return;
          }
          const changedTouch = ev.changedTouches[0];
          dx = currX - changedTouch.pageX;
          dy = currY - changedTouch.pageY;
          currX = changedTouch.pageX;
          currY = changedTouch.pageY;
          toolbarRef.current.style.left = `${toolbarRef.current.offsetLeft -
            dx}px`;
          toolbarRef.current.style.top = `${toolbarRef.current.offsetTop -
            dy}px`;
        };
      }}
    >
      <div id="toolbar-body">{children}</div>
    </div>
  );
};

export default Toolbar;
