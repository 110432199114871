import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { BrowserRouter } from "react-router-dom";
import "./styles";
import { rootSaga, rootReducer } from "./ducks";
import createWebsocketMiddleware from "./middleware/ws";
import App from "./components/App";
import { initToken } from "./util/token";
import { initSentry } from "./util/sentry";
import * as serviceWorker from "./util/serviceWorker";

initToken();
initSentry();
const sagaMiddleware = createSagaMiddleware();
const websocketMiddleware = createWebsocketMiddleware();
const store = createStore(
  rootReducer(),
  applyMiddleware(
    sagaMiddleware,
    websocketMiddleware
  )
);

sagaMiddleware.run(rootSaga);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
