import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Modal } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";
import {
  loginRequested,
  initResetPasswordRequested
} from "../../ducks/auth/actions";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = ({ usernameOrEmail, password }: any): void => {
    dispatch(loginRequested(usernameOrEmail, password, navigate));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const showModal = (): void => {
    setIsModalOpen(true);
  };
  const handleCancel = (): void => {
    setIsModalOpen(false);
  };
  const handleOk = (): void => {
    dispatch(initResetPasswordRequested(resetPasswordEmail));
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Get Password Reset Link"
      >
        Enter your email and we&apos;ll send you a link to reset your password
        <br />
        <br />
        <Input
          onChange={(e): void => setResetPasswordEmail(e.target.value)}
          placeholder="Your Email"
          prefix={<MailOutlined />}
          value={resetPasswordEmail}
        />
      </Modal>
      <Form onFinish={onFinish} autoComplete="off">
        <Form.Item name="usernameOrEmail">
          <Input placeholder="Enter Username/Email" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item>
          <Form.Item name="password" style={{ marginBottom: 0 }}>
            <Input.Password
              placeholder="Enter Password"
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <div>
            <Button
              onClick={showModal}
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
                color: "#1890ff",
                padding: 0
              }}
            >
              Forgotten password?
            </Button>
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Log In
          </Button>
          Don&apos;t already have an account?{" "}
          <Link to="/signup">Sign up here</Link>
        </Form.Item>
      </Form>
    </>
  );
};

export default Login;
