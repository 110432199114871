// send actions
export const UPDATE_CHART_FIELD = "UPDATE_CHART_FIELD";
export const ADD_BAR = "ADD_BAR";
export const DELETE_BAR = "DELETE_BAR";
export const TRANSPOSE = "TRANSPOSE";
export const UPDATE_BAR_FIELD = "UPDATE_BAR_FIELD";
export const UPDATE_CHORD = "UPDATE_CHORD";
export const DELETE_BAR_FIELD = "DELETE_BAR_FIELD";
export const UPDATE_PLAYLIST_FIELD = "UPDATE_PLAYLIST_FIELD";
export const ADD_COLLABORATORS = "ADD_COLLABORATORS";

// receive actions
export const AUTH = "AUTH";
export const ACK = "ACK";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const UPDATE_CURRENTLY_VIEWING = "UPDATE_CURRENTLY_VIEWING";
