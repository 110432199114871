import {
  StartDoubleNarrow,
  StartRepeatNarrow,
  EndDoubleNarrow,
  EndRepeatNarrow,
  FinalNarrow
} from "../../svg/barlines";

export type Props = {
  barline: number;
};

export const StartBarline = (props: Props) => (
  <div className="barline-container">
    {(() => {
      switch (props.barline) {
        case 1:
          return <StartDoubleNarrow />;
        case 2:
          return <StartRepeatNarrow />;
        default:
          return null;
      }
    })()}
  </div>
);

export const EndBarline = (props: Props) => (
  <div className="barline-container">
    {(() => {
      switch (props.barline) {
        case 1:
          return <EndDoubleNarrow />;
        case 2:
          return <EndRepeatNarrow />;
        case 3:
          return <FinalNarrow />;
        default:
          return null;
      }
    })()}
  </div>
);
