import { isValid, splitChord } from "../../../util/chart";

export type Props = {
  chord: string;
  highlighted?: boolean;
  onClick?: () => void;
};

const Chord = ({ chord, highlighted, onClick }: Props) => {
  if (isValid(chord)) {
    let { base, mod, over } = splitChord(chord);
    base = base.replace(/b/g, "♭").replace(/#/g, "♯");
    mod = mod
      .replace(/b/g, "♭")
      .replace(/#/g, "♯")
      .replace(/h/g, "ø")
      .replace(/\^/g, "Δ");
    over = over.replace(/b/g, "♭").replace(/#/g, "♯");
    return (
      <span
        className={`chord${highlighted ? " highlighted" : ""}`}
        onClick={onClick}
        role="presentation"
      >
        {base === "" ? "" : base}
        {mod === "" ? "" : <sup>{mod}</sup>}
        {over === "" ? "" : `/${over}`}
      </span>
    );
  }

  // TODO: possibly change css to signal invalid here
  return (
    <span
      className={`chord${highlighted ? " highlighted" : ""}`}
      onClick={onClick}
      role="presentation"
    >
      {chord
        .replace(/b/g, "♭")
        .replace(/#/g, "♯")
        .replace(/h/g, "ø")
        .replace(/\^/g, "Δ")}
    </span>
  );
};

Chord.defaultProps = {
  highlighted: false,
  onClick: () => { },
};

export default Chord;
