type TimeSignatureProps = {
  numerator: string;
  denominator: string;
};

const TimeSignature = ({
  numerator,
  denominator
}: TimeSignatureProps) => (
  <div className="timesig">
    <div className="numerator">{numerator}</div>
    <div className="denominator">{denominator}</div>
  </div>
);

export default TimeSignature;
