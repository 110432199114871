import { transpose } from "../../util/chart";
import { REINIT, ReinitAction } from "../common/actions";
import * as constants from "./constants";
import * as types from "./types";

export interface State {
  isFetching: boolean;
  chartData: Array<any>;
  title: string;
  style: string;
  artist: string;
  collaborators: Array<string>;
  createdBy: string;
  toolbarOpen: boolean;
  barsPerRow: number;
  unitsPerRow: number;
  editingChord: string;
  isEditing: boolean;
  currentBarID: null | string;
  currentChordPosition: null | number;
  transposeSemitones: number;
  isTransposing: boolean;
  chartID?: string;
}

const initialState: State = {
  isFetching: false,
  chartData: [],
  title: "",
  style: "",
  artist: "",
  collaborators: [],
  createdBy: "",
  toolbarOpen: true,
  barsPerRow: 4,
  unitsPerRow: 12,
  editingChord: "",
  isEditing: false,
  currentBarID: null,
  currentChordPosition: null,
  transposeSemitones: 0,
  isTransposing: false
};

const fetchChartRequested = (
  state: State,
  action: types.FetchChartRequestedAction
): State => {
  const { chartID } = action;
  return {
    ...state,
    chartID,
    isFetching: true
  };
};

const fetchChartSucceeded = (
  state: State,
  action: types.FetchChartSucceededAction
): State => {
  const {
    title,
    style,
    artist,
    chartData,
    collaborators,
    createdBy
  } = action.chart;
  return {
    ...state,
    title,
    style,
    artist,
    chartData,
    collaborators,
    createdBy,
    isFetching: false
  };
};

const recvChart = (state: State, action: types.RecvChartAction): State => {
  const {
    title,
    artist,
    style,
    chartData,
    collaborators,
    createdBy
  } = action.chart;
  return {
    ...state,
    title,
    artist,
    style,
    chartData,
    collaborators,
    createdBy
  };
};

const setCurrentBar = (
  state: State,
  action: types.SetCurrentBarAction
): State => {
  const { barID } = action;
  const { currentBarID, currentChordPosition, chartData } = state;
  if (barID !== currentBarID) {
    // if there is an existing current chord
    if (currentChordPosition !== null) {
      const barFocus = chartData.filter(x => x.id === barID)[0];
      if (barFocus) {
        return {
          ...state,
          currentBarID: barID,
          editingChord: barFocus.barData[currentChordPosition] || "", // handle chord position greater than barData length
          isEditing: false
        };
      }
    }
    return {
      ...state,
      currentBarID: barID,
      editingChord: "",
      isEditing: false
    };
  }
  return state;
};

const setCurrentChord = (
  state: State,
  action: types.SetCurrentChordAction
): State => {
  const { chordPosition, barID } = action;
  const { currentBarID, currentChordPosition, chartData } = state;
  if (barID !== currentBarID || chordPosition !== currentChordPosition) {
    const barFocus = chartData.filter(x => x.id === barID)[0];
    if (barFocus) {
      const editingChord = barFocus.barData[chordPosition];
      return {
        ...state,
        currentChordPosition: chordPosition,
        currentBarID: barID,
        editingChord: editingChord || "",
        isEditing: false
      };
    }
  }
  return state;
};

const setPrevChord = (state: State): State => {
  const { currentBarID, currentChordPosition, chartData } = state;
  if (currentChordPosition === null) {
    return state;
  }
  // if not the first chord
  if (currentChordPosition !== 0) {
    const barFocus = chartData.filter(x => x.id === currentBarID)[0];
    return {
      ...state,
      currentChordPosition: currentChordPosition - 1,
      editingChord: barFocus.barData[currentChordPosition - 1]
    };
  }
  // if first chord but not the first bar
  const barPos = chartData.findIndex(x => x.id === currentBarID);
  if (barPos !== 0) {
    const prevBar = chartData[barPos - 1];
    return {
      ...state,
      currentChordPosition: prevBar.barData.length - 1,
      currentBarID: prevBar.id,
      editingChord: prevBar.barData[prevBar.barData.length - 1]
    };
  }
  return state;
};

const setNextChord = (state: State): State => {
  const { currentBarID, currentChordPosition, chartData } = state;
  if (currentChordPosition === null) {
    return state;
  }
  const barFocus = chartData.filter(x => x.id === currentBarID)[0];
  // if not the last chord
  if (currentChordPosition !== barFocus.barData.length - 1) {
    return {
      ...state,
      currentChordPosition: currentChordPosition + 1,
      editingChord: barFocus.barData[currentChordPosition + 1]
    };
  }
  // if last chord but not the last bar
  const barPos = chartData.findIndex(x => x.id === currentBarID);
  if (barPos !== chartData.length - 1) {
    const nextBar = chartData[barPos + 1];
    return {
      ...state,
      currentChordPosition: 0,
      currentBarID: nextBar.id,
      editingChord: nextBar.barData[0]
    };
  }
  return state;
};

const deleteBar = (state: State): State => {
  const { currentBarID, chartData } = state;
  if (currentBarID !== null) {
    const barPos = chartData.findIndex(x => x.id === currentBarID);
    // if chart has no bars, set current bar to null
    if (chartData.length === 1) {
      return {
        ...state,
        currentBarID: null,
        chartData: []
      };
      // if bar is the last bar, set current bar to the post-delete last bar
    }
    if (barPos === chartData.length - 1) {
      return {
        ...state,
        currentBarID: chartData[barPos - 1].id,
        chartData: [...chartData.slice(0, barPos)]
      };
      // set current bar to the next bar
    }
    return {
      ...state,
      currentBarID: chartData[barPos + 1].id,
      chartData: [...chartData.slice(0, barPos), ...chartData.slice(barPos + 1)]
    };
  }
  return state;
};

const increaseBarWidth = (state: State): State => {
  const { currentBarID, chartData } = state;
  if (currentBarID !== null) {
    const barFocus = chartData.filter(x => x.id === currentBarID)[0];
    if (barFocus) {
      const width = barFocus.barWidth;
      if (width >= 1 && width <= 3) {
        return {
          ...state,
          chartData: chartData.map(x =>
            x.id === currentBarID ? { ...x, barWidth: width + 1 } : x
          )
        };
      }
    }
  }
  return state;
};

const decreaseBarWidth = (state: State): State => {
  const { currentBarID, chartData } = state;
  if (currentBarID !== null) {
    const barFocus = chartData.filter(x => x.id === currentBarID)[0];
    if (barFocus) {
      const width = barFocus.barWidth;
      if (width >= 2 && width <= 4) {
        return {
          ...state,
          chartData: chartData.map(x =>
            x.id === currentBarID ? { ...x, barWidth: width - 1 } : x
          )
        };
      }
    }
  }
  return state;
};

const extendChord = (state: State, action: types.ExtendChordAction): State => {
  const { extend } = action;
  // if the extension is valid
  if (extend) {
    const { editingChord } = state;
    return {
      ...state,
      editingChord: editingChord + extend,
      isEditing: true
    };
  }
  return state;
};

const reduceChord = (state: State): State => {
  const { editingChord } = state;
  return {
    ...state,
    editingChord: editingChord.slice(0, editingChord.length - 1),
    isEditing: true
  };
};

const clearChord = (state: State): State => ({
  ...state,
  editingChord: "",
  isEditing: true
});

const cancelEdits = (state: State): State => {
  const { chartData, currentBarID, currentChordPosition } = state;
  let editingChord = "";
  if (currentBarID !== null && currentChordPosition !== null) {
    const barFocus = chartData.filter(x => x.id === state.currentBarID)[0];
    if (barFocus) {
      const chord = barFocus.barData[currentChordPosition];
      if (chord) editingChord = chord;
    }
  }
  return {
    ...state,
    editingChord,
    isEditing: false,
    isTransposing: false,
    transposeSemitones: 0
  };
};

const updateChord = (state: State): State => {
  const { currentChordPosition } = state;
  if (currentChordPosition === null) {
    return state;
  }
  const chartData = state.chartData.map(bar => {
    if (bar.id === state.currentBarID) {
      bar.barData[currentChordPosition] = state.editingChord;
      return bar;
    }
    return bar;
  });
  return {
    ...state,
    chartData,
    isEditing: false
  };
};

const toggleToolbar = (state: State): State => ({
  ...state,
  toolbarOpen: !state.toolbarOpen
});

const transposeUp = (state: State): State => {
  const { transposeSemitones } = state;
  return {
    ...state,
    transposeSemitones: (transposeSemitones + 13) % 12,
    isTransposing: true,
    isEditing: false
  };
};

const transposeDown = (state: State): State => {
  const { transposeSemitones } = state;
  return {
    ...state,
    transposeSemitones: (transposeSemitones - 13) % 12,
    isTransposing: true,
    isEditing: false
  };
};

const transposeExec = (state: State): State => {
  const chartData = transpose(state.chartData, state.transposeSemitones);
  return {
    ...state,
    chartData,
    transposeSemitones: 0,
    isTransposing: false
  };
};

const reducer = (
  state: State = initialState,
  action:
    | ReinitAction
    | types.FetchChartRequestedAction
    | types.FetchChartSucceededAction
    | types.FetchChartFailedAction
    | types.RecvChartAction
    | types.SetCurrentBarAction
    | types.SetCurrentChordAction
    | types.SetPrevChordAction
    | types.SetNextChordAction
    | types.DeleteBarAction
    | types.IncreaseBarWidthAction
    | types.DecreaseBarWidthAction
    | types.ExtendChordAction
    | types.ReduceChordAction
    | types.ClearChordAction
    | types.CancelEditsAction
    | types.UpdateChordAction
    | types.ToggleToolbarAction
    | types.TransposeUpAction
    | types.TransposeDownAction
    | types.TransposeAction
): State => {
  switch (action.type) {
    case REINIT: {
      return initialState;
    }
    case constants.FETCH_CHART_REQUESTED: {
      return fetchChartRequested(state, action);
    }
    case constants.FETCH_CHART_SUCCEEDED: {
      return fetchChartSucceeded(state, action);
    }
    case constants.FETCH_CHART_FAILED: {
      return initialState;
    }
    case constants.RECV_CHART: {
      return recvChart(state, action);
    }
    case constants.SET_CURRENTBAR: {
      return setCurrentBar(state, action);
    }
    case constants.SET_CURRENTCHORD: {
      return setCurrentChord(state, action);
    }
    case constants.SET_PREVCHORD: {
      return setPrevChord(state);
    }
    case constants.SET_NEXTCHORD: {
      return setNextChord(state);
    }
    case constants.DELETE_BAR: {
      return deleteBar(state);
    }
    case constants.INCREASE_BAR_WIDTH: {
      return increaseBarWidth(state);
    }
    case constants.DECREASE_BAR_WIDTH: {
      return decreaseBarWidth(state);
    }
    case constants.EXTEND_CHORD: {
      return extendChord(state, action);
    }
    case constants.REDUCE_CHORD: {
      return reduceChord(state);
    }
    case constants.CLEAR_CHORD: {
      return clearChord(state);
    }
    case constants.CANCEL_EDITS: {
      return cancelEdits(state);
    }
    case constants.UPDATE_CHORD: {
      return updateChord(state);
    }
    case constants.TOGGLE_TOOLBAR: {
      return toggleToolbar(state);
    }
    case constants.TRANSPOSE_UP: {
      return transposeUp(state);
    }
    case constants.TRANSPOSE_DOWN: {
      return transposeDown(state);
    }
    case constants.TRANSPOSE: {
      return transposeExec(state);
    }
    default:
      return state;
  }
};

export default reducer;
