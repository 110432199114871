import {
  takeLatest,
  call,
  put,
  select,
  fork,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect
} from "redux-saga/effects";
import { message } from "antd";
import {
  fetchChartAPI,
  cloneChartAPI,
  addChartAPI,
  deleteChartAPI
} from "../../api/chart";
import { captureException } from "../../util/sentry";
import * as actions from "./actions";
import * as constants from "./constants";
import * as types from "./types";
import * as wsTypes from "../ws/types";
import { wsConnectChart } from "../ws/actions";
import { Chart } from "../../types";

function* fetchChart({
  chartID
}: types.FetchChartRequestedAction): Generator<
  | CallEffect<Chart>
  | PutEffect<types.FetchChartSucceededAction>
  | SelectEffect
  | PutEffect<wsTypes.WebsocketConnectAction>
  | PutEffect<types.FetchChartFailedAction>
> {
  try {
    const res: any = yield call(fetchChartAPI, chartID);
    yield put(actions.fetchChartSucceeded(res));
    // set up websocket if user is a collaborator
    const isCollaborator = yield select(
      (state: { auth: { username: string } }) =>
        res.collaborators.includes(state.auth.username)
    );
    if (isCollaborator) yield put(wsConnectChart(chartID));
  } catch (e) {
    yield put(actions.fetchChartFailed());
    captureException(e);
  }
}

function* cloneChart({
  chartID,
  navigate
}: types.CloneChartRequestedAction): Generator<
  | CallEffect<{ chartID: string }>
  | PutEffect<types.CloneChartSucceededAction>
  | PutEffect<types.CloneChartFailedAction>
> {
  try {
    const { chartID: newChartID }: any = yield call(cloneChartAPI, chartID);
    yield put(actions.cloneChartSucceeded());
    navigate(`/charts/${newChartID}`);
    message.success("Successfully cloned chart!");
  } catch (e) {
    yield put(actions.cloneChartFailed());
    captureException(e);
  }
}

function* addChart({
  navigate
}: types.AddChartRequestedAction): Generator<
  | CallEffect<{ chartID: string }>
  | PutEffect<types.AddChartSucceededAction>
  | PutEffect<types.AddChartFailedAction>
> {
  try {
    const { chartID }: any = yield call(addChartAPI);
    yield put(actions.addChartSucceeded());
    navigate(`/charts/${chartID}`);
    message.success("Successfully added chart!");
  } catch (e) {
    yield put(actions.addChartFailed());
    captureException(e);
  }
}

function* deleteChart({
  chartID,
  navigate
}: types.DeleteChartRequestedAction): Generator<
  | CallEffect<Response>
  | PutEffect<types.DeleteChartSucceededAction>
  | PutEffect<types.DeleteChartFailedAction>
> {
  try {
    const { status }: any = yield call(deleteChartAPI, chartID);
    if (status === 200) {
      yield put(actions.deleteChartSucceeded());
      navigate("/");
      message.success("Successfully deleted chart!");
    } else {
      yield put(actions.deleteChartFailed());
      message.error("Failed to delete chart");
    }
  } catch (e) {
    yield put(actions.deleteChartFailed());
    captureException(e);
  }
}

function* fetchChartSaga(): Generator<
  ForkEffect<types.FetchChartRequestedAction>
> {
  yield takeLatest(constants.FETCH_CHART_REQUESTED, fetchChart);
}
function* cloneChartSaga(): Generator<
  ForkEffect<types.CloneChartRequestedAction>
> {
  yield takeLatest(constants.CLONE_CHART_REQUESTED, cloneChart);
}
function* addChartSaga(): Generator<ForkEffect<types.AddChartRequestedAction>> {
  yield takeLatest(constants.ADD_CHART_REQUESTED, addChart);
}
function* deleteChartSaga(): Generator<
  ForkEffect<types.DeleteChartRequestedAction>
> {
  yield takeLatest(constants.DELETE_CHART_REQUESTED, deleteChart);
}

const a = [
  fork(fetchChartSaga),
  fork(cloneChartSaga),
  fork(addChartSaga),
  fork(deleteChartSaga)
];
export default a;
