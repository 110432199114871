import Header from "../chartDetail/Header";
import Coda from "../barDetail/Coda";
import AnnotationTextUneditableView from "../barDetail/AnnotationTextUneditable";
import { StartBarline, EndBarline } from "../barDetail/Barline";
import TimeSignatureView from "../barDetail/TimeSignature";
import Chord from "../barDetail/Chord";
import {
  setShowTS,
  chunk,
  hasTop,
  hasBtm,
  transpose
} from "../../../util/chart";
import TimeBarComponent from "../../svg/timebar";
import { useSelector } from "react-redux";
import { State } from "../../../ducks";

const UneditableChart = () => {
  const chartData = useSelector((state: State) =>
    state.chart.isTransposing
      ? transpose(state.chart.chartData, state.chart.transposeSemitones)
      : state.chart.chartData
  );
  const barsPerRow = useSelector((state: State) => state.chart.barsPerRow);
  const unitsPerBar = useSelector(
    (state: State) => state.chart.unitsPerRow / state.chart.barsPerRow
  );

  return (
    <div className="chart-nottoolbar">
      <div className="chart-paper">
        <Header />
        {chunk(setShowTS(chartData), barsPerRow).map(barChunk => (
          <div
            className={`chart-row${hasTop(barChunk) ? " has-top" : ""}${
              hasBtm(barChunk) ? " has-btm" : ""
            }`}
            key={barChunk[0].id}
          >
            {barChunk.map(bar => {
              const {
                section,
                timeBar,
                coda,
                comment,
                startBarline,
                endBarline,
                barWidth,
                barData,
                denominator,
                showTS,
                id
              } = bar;
              return (
                <div
                  className={`bar is-${(unitsPerBar * barWidth).toString()}`}
                  key={id}
                >
                  {typeof coda === "number" && <Coda symbol={coda} />}
                  {typeof section === "string" && (
                    <AnnotationTextUneditableView
                      field="section"
                      value={section}
                    />
                  )}
                  {typeof comment === "string" && (
                    <AnnotationTextUneditableView
                      field="comment"
                      value={comment}
                    />
                  )}
                  {typeof timeBar === "number" && (
                    <div className="annot timebar">
                      <TimeBarComponent n={timeBar} />
                    </div>
                  )}
                  {typeof startBarline === "number" && (
                    <StartBarline barline={startBarline} />
                  )}
                  {showTS && (
                    <TimeSignatureView
                      numerator={barData.length.toString()}
                      denominator={denominator.toString()}
                    />
                  )}
                  {barData.map((chord, i) => (
                    <Chord key={i} chord={chord} />
                  ))}
                  {typeof endBarline === "number" && (
                    <EndBarline barline={endBarline} />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UneditableChart;
