import { getUsername } from "../../util";
import * as constants from "./constants";
import * as types from "./types";

export interface State {
  username: string;
}

export const initialState = (): State => ({
  username: getUsername()
});

const loginSucceededReducer = (
  state: State,
  action: types.LoginSucceededAction
): State => {
  const { username } = action;
  return {
    ...state,
    username
  };
};

const logoutSucceededReducer = (state: State): State => ({
  ...state,
  username: ""
});

const reducer = (
  state: State = initialState(),
  action: types.LoginSucceededAction | types.LogoutSucceededAction
): State => {
  switch (action.type) {
    case constants.LOGIN_SUCCEEDED: {
      return loginSucceededReducer(state, action);
    }
    case constants.LOGOUT_SUCCEEDED: {
      return logoutSucceededReducer(state);
    }
    default:
      return state;
  }
};

export default reducer;
