import { useEffect } from "react";
import { Spin } from "antd";
import { activateAccountRequested } from "../../ducks/auth/actions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const Activate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, token } = useParams();
  useEffect(() => {
    dispatch(activateAccountRequested(username!, token!, navigate));
  }, [dispatch, username, token, navigate]);
  return <Spin />;
};

export default Activate;
