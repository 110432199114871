import * as constants from "./constants";
import * as types from "./types";

export const updateChartField = (
  field: string,
  value: any
): types.UpdateChartFieldAction => ({
  type: constants.UPDATE_CHART_FIELD,
  payload: { field, value }
});

export const addBar = (
  insertAt: number,
  barData: Array<string>,
  barWidth: number,
  denominator: number
): types.AddBarAction => ({
  type: constants.ADD_BAR,
  payload: {
    insertAt,
    barData,
    barWidth,
    denominator
  }
});

export const deleteBar = (barID: string): types.DeleteBarAction => ({
  type: constants.DELETE_BAR,
  payload: { barID }
});

export const transpose = (bars: types.Bars): types.TransposeAction => ({
  type: constants.TRANSPOSE,
  payload: { bars }
});

export const updateBarField = (
  barID: string,
  field: string,
  value: any
): types.UpdateBarFieldAction => ({
  type: constants.UPDATE_BAR_FIELD,
  payload: { barID, field, value }
});

export const updateChord = (
  barID: string,
  position: number,
  chord: string
): types.UpdateChordAction => ({
  type: constants.UPDATE_CHORD,
  payload: { barID, position, chord }
});

export const deleteBarField = (
  barID: string,
  field: string
): types.DeleteBarFieldAction => ({
  type: constants.DELETE_BAR_FIELD,
  payload: { barID, field }
});

export const updatePlaylistField = (
  field: string,
  value: any
): types.UpdatePlaylistFieldAction => ({
  type: constants.UPDATE_PLAYLIST_FIELD,
  payload: { field, value }
});

export const addCollaborators = (
  collaborators: Array<string>
): types.AddCollaboratorsAction => ({
  type: constants.ADD_COLLABORATORS,
  payload: { collaborators }
});
