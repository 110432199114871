import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { resetPasswordRequested } from "../../ducks/auth/actions";

const Reset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const onFinish = ({ password }: any): void => {
    setIsLoading(false);
    dispatch(
      resetPasswordRequested(
        params.get("e")!,
        params.get("t")!,
        password,
        navigate,
      )
    );
  };

  return (
    <Form onFinish={onFinish} autoComplete="off">
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: ""
          }
        ]}
      >
        <Input.Password
          placeholder="Enter Password"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Repeat Password"
        name="password2"
        rules={[
          {
            required: true,
            message: ""
          },
          ({ getFieldValue }): any => ({
            validator: (): Promise<void> => {
              if (getFieldValue("password") === getFieldValue("password2")) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords do not match"));
            }
          })
        ]}
      >
        <Input.Password
          placeholder="Enter Repeat Password"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ width: "100%" }}
        >
          Update Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Reset;
