import { updatePlaylistField, addCollaborators } from "./actions";
import { State as PlaylistState } from "../../ducks/playlist/reducers";
import * as types from "./types";

export const wsUpdatePlaylistField = (params: {
  field: string;
  value: any;
}): types.UpdatePlaylistFieldAction => {
  const { field, value } = params;
  return updatePlaylistField(field, value);
};

export const wsAddCharts = (params: {
  chartIDs: Array<string>;
}): types.UpdatePlaylistFieldAction => {
  const { chartIDs } = params;
  return updatePlaylistField("playlistData", chartIDs);
};

export const wsDeleteChart = (
  state: PlaylistState,
  params: { chartID: string }
): types.UpdatePlaylistFieldAction => {
  const { chartID } = params;
  const playlistData = state.playlistData.filter(id => id !== chartID);
  return updatePlaylistField("playlistData", playlistData);
};

export const wsReorderCharts = (params: {
  playlistData: Array<string>;
}): types.UpdatePlaylistFieldAction => {
  const { playlistData } = params;
  return updatePlaylistField("playlistData", playlistData);
};

export const wsAddCollaborators = (
  params: any
): types.AddCollaboratorsAction => {
  const { collaborators } = params;
  return addCollaborators(collaborators);
};
