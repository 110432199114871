export const ADD_BAR = "chart/ADD_BAR";
export const ADD_CHART_FAILED = "chart/ADD_CHART_FAILED";
export const ADD_CHART_REQUESTED = "chart/ADD_CHART_REQUESTED";
export const ADD_CHART_SUCCEEDED = "chart/ADD_CHART_SUCCEEDED";
export const ADD_COLLABORATORS = "chart/ADD_COLLABORATORS";
export const CANCEL_EDITS = "chart/CANCEL_EDITS";
export const CLEAR_CHORD = "chart/CLEAR_CHORD";
export const CLONE_CHART_FAILED = "chart/CLONE_CHART_FAILED";
export const CLONE_CHART_REQUESTED = "chart/CLONE_CHART_REQUESTED";
export const CLONE_CHART_SUCCEEDED = "chart/CLONE_CHART_SUCCEEDED";
export const DECREASE_BAR_WIDTH = "chart/DECREASE_BAR_WIDTH";
export const DELETE_BAR = "chart/DELETE_BAR";
export const DELETE_BAR_FIELD = "chart/DELETE_BAR_FIELD";
export const DELETE_CHART_FAILED = "chart/DELETE_CHART_FAILED";
export const DELETE_CHART_REQUESTED = "chart/DELETE_CHART_REQUESTED";
export const DELETE_CHART_SUCCEEDED = "chart/DELETE_CHART_SUCCEEDED";
export const EXTEND_CHORD = "chart/EXTEND_CHORD";
export const FETCH_CHART_FAILED = "chart/FETCH_CHART_FAILED";
export const FETCH_CHART_REQUESTED = "chart/FETCH_CHART_REQUESTED";
export const FETCH_CHART_SUCCEEDED = "chart/FETCH_CHART_SUCCEEDED";
export const INCREASE_BAR_WIDTH = "chart/INCREASE_BAR_WIDTH";
export const RECV_CHART = "chart/RECV_CHART";
export const REDUCE_CHORD = "chart/REDUCE_CHORD";
export const SET_CURRENTBAR = "chart/SET_CURRENTBAR";
export const SET_CURRENTCHORD = "chart/SET_CURRENTCHORD";
export const SET_NEXTCHORD = "chart/SET_NEXTCHORD";
export const SET_PREVCHORD = "chart/SET_PREVCHORD";
export const TOGGLE_BAR_FIELD = "chart/TOGGLE_BAR_FIELD";
export const TOGGLE_TOOLBAR = "chart/TOGGLE_TOOLBAR";
export const TRANSPOSE = "chart/TRANSPOSE";
export const TRANSPOSE_DOWN = "chart/TRANSPOSE_DOWN";
export const TRANSPOSE_UP = "chart/TRANSPOSE_UP";
export const UPDATE_BAR_FIELD = "chart/UPDATE_BAR_FIELD";
export const UPDATE_CHART_FIELD = "chart/UPDATE_CHART_FIELD";
export const UPDATE_CHORD = "chart/UPDATE_CHORD";
export const UPDATE_DENOMINATOR = "chart/UPDATE_DENOMINATOR";
export const UPDATE_NUMERATOR = "chart/UPDATE_NUMERATOR";
