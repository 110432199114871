import { NavigateFunction } from "react-router-dom";
import * as constants from "./constants";
import * as types from "./types";

export const loginRequested = (
  usernameOrEmail: string,
  password: string,
  navigate: NavigateFunction
): types.LoginRequestedAction => ({
  type: constants.LOGIN_REQUESTED,
  usernameOrEmail,
  password,
  navigate,
});

export const loginSucceeded = (
  username: string
): types.LoginSucceededAction => ({
  type: constants.LOGIN_SUCCEEDED,
  username
});

export const initResetPasswordFailed = (): types.ResetPasswordFailedAction => ({
  type: constants.RESETPASSWORD_FAILED
});

export const initResetPasswordRequested = (
  email: string
): types.InitResetPasswordRequestedAction => ({
  type: constants.INITRESETPASSWORD_REQUESTED,
  email
});

export const resetPasswordFailed = (): types.ResetPasswordFailedAction => ({
  type: constants.RESETPASSWORD_FAILED
});

export const resetPasswordRequested = (
  email: string,
  token: string,
  password: string,
  navigate: NavigateFunction
): types.ResetPasswordRequestedAction => ({
  type: constants.RESETPASSWORD_REQUESTED,
  email,
  token,
  password,
  navigate
});

export const loginFailed = (): types.LoginFailedAction => ({
  type: constants.LOGIN_FAILED
});

export const logoutRequested = (navigate: NavigateFunction): types.LogoutRequestedAction => ({
  type: constants.LOGOUT_REQUESTED,
  navigate,
});

export const logoutSucceeded = (): types.LogoutSucceededAction => ({
  type: constants.LOGOUT_SUCCEEDED
});

export const signupRequested = (
  username: string,
  email: string,
  password: string,
  grecaptcha: string
): types.SignupRequestedAction => ({
  type: constants.SIGNUP_REQUESTED,
  username,
  email,
  password,
  grecaptcha
});

export const signupFailed = (): types.SignupFailedAction => ({
  type: constants.SIGNUP_FAILED
});

export const activateAccountRequested = (
  username: string,
  token: string,
  navigate: NavigateFunction
): types.ActivateAccountRequestedAction => ({
  type: constants.ACTIVATEACCOUNT_REQUESTED,
  username,
  token,
  navigate
});

export const activateAccountSucceeded = (
  username: string
): types.ActivateAccountSucceededAction => ({
  type: constants.ACTIVATEACCOUNT_SUCCEEDED,
  username
});

export const activateAccountFailed = (): types.ActivateAccountFailedAction => ({
  type: constants.ACTIVATEACCOUNT_FAILED
});
