import * as constants from "./constants";
import * as types from "./types";
import { recvChart } from "../chart/actions";
import { recvPlaylist } from "../playlist/actions";
import { Action } from "redux";

export const wsOpen = (): types.WebsocketOpenAction => ({
  type: constants.WEBSOCKET_OPEN
});

export const wsClose = (): types.WebsocketCloseAction => ({
  type: constants.WEBSOCKET_CLOSE
});

const wsConnect = (
  namespace: string,
  id: string,
  recv: (payload: any) => Action
): types.WebsocketConnectAction => ({
  type: constants.WEBSOCKET_CONNECT,
  url: `${
    process.env.REACT_APP_WS_URL
  }/ws/${namespace}/${id}`,
  recv
});
export const wsConnectChart = (id: string): types.WebsocketConnectAction =>
  wsConnect("charts", id, recvChart);
export const wsConnectPlaylist = (id: string): types.WebsocketConnectAction =>
  wsConnect("playlists", id, recvPlaylist);

export const wsDisconnect = (): types.WebsocketDisconnectAction => ({
  type: constants.WEBSOCKET_DISCONNECT
});

export const expAck = (guid: string): types.ExpAckAction => ({
  type: constants.EXP_ACK,
  guid
});

export const recvAck = ({
  guid,
  guid2
}: {
  guid: string;
  guid2: string;
}): types.RecvAckAction => ({
  type: constants.RECV_ACK,
  guid,
  guid2
});

export const recvAck2 = (guid2: string): types.RecvAck2Action => ({
  type: constants.RECV_ACK2,
  guid2
});

export const recvCurrentlyViewing = ({
  currentlyViewing
}: {
  currentlyViewing: Array<string>;
}): types.RecvCurrentlyViewingAction => ({
  type: constants.RECV_CURRENTLYVIEWING,
  currentlyViewing
});
