import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { message } from "antd";

import Nav from "./fixed/Nav";
import Charts from "./main/Charts";
import Signup from "./main/Signup";
import Activate from "./main/Activate";
import Login from "./main/Login";
import Reset from "./main/Reset";
import YourCharts from "./main/YourCharts";
import YourPlaylists from "./main/YourPlaylists";
import Chart from "./chart/Chart";
import Playlist from "./playlist/Playlist";
import { componentCaptureException } from "../util/sentry";

// see https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
const RequireAuth = ({ children }: {
  children: JSX.Element,
}) => {
  let isAuthenticated = localStorage.getItem("access_token");
  return isAuthenticated ? children : <Navigate to={"/"} />;
}

export default class App extends Component {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    componentCaptureException(error, errorInfo);
    message.error("Something seems wrong...");
  }

  render() {
    return (
      <Routes>
        <Route path="/charts/:id" element={<Chart />} />
        <Route path="/playlists/:id" element={<Playlist />} />
        <Route path="/*" element={<Nav>
          <Routes>
            <Route path="/" element={<Charts />} />
            <Route path="/search" element={<Charts />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/activate/:username/:token"
              element={<Activate />}
            />
            <Route path="/login" element={<Login />}/>
            <Route path="/reset" element={<Reset />}/>
            <Route path="/charts" element={
              <RequireAuth>
                <YourCharts />
              </RequireAuth>
            }/>
            <Route path="/playlists" element={
              <RequireAuth>
                <YourPlaylists />
              </RequireAuth>
            }/>
            <Route path="/*" element={<div>404 not found</div>} />
          </Routes>
        </Nav>}/>
      </Routes>
    );
  }
}
