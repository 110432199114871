import { REINIT, ReinitAction } from "../common/actions";
import * as constants from "./constants";
import * as types from "./types";

export interface State {
  wsStatus: string;
  ack: { [key: string]: boolean };
  ack2: { [key: string]: boolean };
  currentlyViewing: Array<string>;
}

const initialState: State = {
  wsStatus: "Closed",
  ack: {},
  ack2: {},
  currentlyViewing: []
};

export const websocketConnecting = (state: State): State => {
  const wsStatus = "Connecting";
  return {
    ...state,
    wsStatus
  };
};

export const websocketOpen = (state: State): State => {
  const wsStatus = "Open";
  return {
    ...state,
    wsStatus
  };
};

export const websocketClose = (state: State): State => {
  const wsStatus = "Closed";
  return {
    ...state,
    wsStatus
  };
};

export const expAck = (state: State, action: types.ExpAckAction): State => {
  const { guid } = action;
  return {
    ...state,
    ack: {
      ...state.ack,
      [guid]: true
    }
  };
};

export const recvAck = (state: State, action: types.RecvAckAction): State => {
  const { guid, guid2 } = action;
  if (guid2 === "") return state; // TODO: handle the error here
  const { [guid]: _, ...ack } = state.ack;
  return {
    ...state,
    ack,
    ack2: {
      ...state.ack2,
      [guid2]: true
    }
  };
};

export const recvAck2 = (state: State, action: types.RecvAck2Action): State => {
  const { guid2 } = action;
  const { [guid2]: _, ...ack2 } = state.ack2;
  return {
    ...state,
    ack2
  };
};

export const recvCurrentlyViewing = (
  state: State,
  action: types.RecvCurrentlyViewingAction
): State => {
  const { currentlyViewing } = action;
  return { ...state, currentlyViewing };
};

const reducer = (
  state: State = initialState,
  action:
    | ReinitAction
    | types.WebsocketConnectAction
    | types.WebsocketOpenAction
    | types.WebsocketCloseAction
    | types.ExpAckAction
    | types.RecvAckAction
    | types.RecvAck2Action
    | types.RecvCurrentlyViewingAction
): State => {
  switch (action.type) {
    case REINIT: {
      return initialState;
    }
    case constants.WEBSOCKET_CONNECT: {
      return websocketConnecting(state);
    }
    case constants.WEBSOCKET_OPEN: {
      return websocketOpen(state);
    }
    case constants.WEBSOCKET_CLOSE: {
      return websocketClose(state);
    }
    case constants.EXP_ACK: {
      return expAck(state, action);
    }
    case constants.RECV_ACK: {
      return recvAck(state, action);
    }
    case constants.RECV_ACK2: {
      return recvAck2(state, action);
    }
    case constants.RECV_CURRENTLYVIEWING: {
      return recvCurrentlyViewing(state, action);
    }
    default:
      return state;
  }
};

export default reducer;
