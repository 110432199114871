import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tooltip, Button, Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faCompressAlt,
  faExpandAlt,
  faHourglassStart,
  faHourglassEnd,
  faHSquare,
  faCommentAlt,
  faEraser,
  faBackspace,
  faArrowCircleLeft,
  faArrowCircleRight,
  faCheck,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import Toolbar from "../Toolbar";
import {
  StartRepeatSquare,
  EndRepeatSquare,
  StartDoubleSquare,
  EndDoubleSquare,
  FinalSquare
} from "../../svg/barlines";
import { Coda, Segno } from "../../svg/symbols";
import TimeBarComponent from "../../svg/timebar";
import {
  cancelEdits,
  addBar,
  deleteBar,
  incBarWidth,
  decBarWidth,
  updateNumerator,
  updateDenominator,
  updateBarField,
  deleteBarField,
  toggleBarField,
  updateChord,
  transpose,
  prevChord,
  nextChord,
  extendChord,
  reduceChord,
  clearChord
} from "../../../ducks/chart/actions";
import { State } from "../../../ducks";

type ToolbarPlainButton = {
  icon: React.ReactNode;
  handler: () => void;
};

type ToolbarTooltipButton = {
  title: string;
  icon: React.ReactNode;
  handler: () => void;
};

type ToolbarDropdownButton = {
  icon: React.ReactNode;
  handlers: { icon: React.ReactNode; handler: () => void }[];
};

type ToolbarButton =
  | ToolbarPlainButton
  | ToolbarTooltipButton
  | ToolbarDropdownButton;

const PaneContent = (rows: ToolbarButton[][]) => {
  const iconWrapper = (icon: React.ReactNode) => (
    <span style={{ width: "1em" }}>{icon}</span>
  );
  return rows.map((row: ToolbarButton[], rowIdx: number) => (
    <div
      style={{ width: "100%", display: "flex", flexDirection: "row" }}
      key={rowIdx}
    >
      {row.map(
        (button: ToolbarButton, buttonIdx: number) => (
          <div key={buttonIdx} style={{ flexGrow: 1, padding: 2 }}>
            {// this check looks stupid, but is actually part of the documentation
              // see https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
              (button as ToolbarDropdownButton).handlers !== undefined ? (
                <Dropdown
                  menu={{
                    items: (button as ToolbarDropdownButton).handlers.map(
                      (a, menuIdx: number) => ({
                        key: menuIdx,
                        onClick: a.handler,
                        icon: <span style={{ width: "1rem" }} >{a.icon}</span>,
                      })
                    ),
                  }}
                  overlayStyle={{ backgroundColor: "red" }}
                  trigger={["click"]}
                >
                  <Button
                    style={{ width: "100%" }}
                    icon={iconWrapper(button.icon)}
                  />
                </Dropdown>
              ) : (button as ToolbarTooltipButton).title !== undefined ? (
                <Tooltip
                  key={buttonIdx}
                  title={(button as ToolbarTooltipButton).title}
                >
                  <Button
                    style={{ width: "100%" }}
                    icon={iconWrapper(button.icon)}
                    onClick={(button as ToolbarTooltipButton).handler}
                  />
                </Tooltip>
              ) : (
                <Button
                  style={{ width: "100%" }}
                  icon={iconWrapper(button.icon)}
                  onClick={(button as ToolbarPlainButton).handler}
                />
              )}
          </div>
        )
      )}
    </div>
  ));
};

const EditableToolbar = () => {
  const dispatch = useDispatch();
  const isEditing = useSelector((state: State) => state.chart.isEditing);
  const isTransposing = useSelector(
    (state: State) => state.chart.isTransposing
  );
  return (
    <Toolbar>
      <Tabs
        size="small"
        style={{ backgroundColor: "rgb(250,250,250)" }}
        tabBarStyle={{ marginBottom: 2 }}
        tabBarGutter={10}
        type="card"
        items={[
          {
            label: "Edit Bar",
            key: "1",
            children: PaneContent([
              [
                {
                  title: "Add Bar",
                  icon: <FontAwesomeIcon icon={faPlus} />,
                  handler: (): void => {
                    dispatch(addBar());
                  }
                },
                {
                  title: "Delete Bar",
                  icon: <FontAwesomeIcon icon={faTimes} />,
                  handler: (): void => {
                    dispatch(deleteBar());
                  }
                },
                {
                  // TODO: make this idempotent - use fixed number widths
                  title: "Decrease Bar Width",
                  icon: <FontAwesomeIcon icon={faCompressAlt} />,
                  handler: (): void => {
                    dispatch(decBarWidth());
                  }
                },
                {
                  title: "Increase Bar Width",
                  icon: <FontAwesomeIcon icon={faExpandAlt} />,
                  handler: (): void => {
                    dispatch(incBarWidth());
                  }
                },
                {
                  icon: <FontAwesomeIcon icon={faHourglassStart} />,
                  handlers: [2, 3, 4, 5, 6, 7].map((n): {
                    icon: React.ReactNode;
                    handler: () => void;
                  } => ({
                    icon: n,
                    handler: (): void => {
                      dispatch(updateNumerator(n));
                    }
                  }))
                },
                {
                  icon: <FontAwesomeIcon icon={faHourglassEnd} />,
                  handlers: [2, 4, 8, 16].map((n): {
                    icon: React.ReactNode;
                    handler: () => void;
                  } => ({
                    icon: n,
                    handler: (): void => {
                      dispatch(updateDenominator(n));
                    }
                  }))
                }
              ],
              [
                {
                  icon: <TimeBarComponent n={1} />,
                  handlers: [
                    {
                      icon: <FontAwesomeIcon icon={faTimesCircle} />,
                      handler: (): void => {
                        dispatch(deleteBarField("timeBar"));
                      }
                    },
                    ...[1, 2, 3].map(n => ({
                      icon: <TimeBarComponent n={n} />,
                      handler: (): void => {
                        dispatch(updateBarField("timeBar", n));
                      }
                    }))
                  ]
                },
                {
                  icon: <Coda />,
                  handlers: [
                    {
                      icon: <FontAwesomeIcon icon={faTimesCircle} />,
                      handler: (): void => {
                        dispatch(deleteBarField("coda"));
                      }
                    },
                    {
                      icon: <Coda />,
                      handler: (): void => {
                        dispatch(updateBarField("coda", 1));
                      }
                    },
                    {
                      icon: <Segno />,
                      handler: (): void => {
                        dispatch(updateBarField("coda", 2));
                      }
                    }
                  ]
                },
                {
                  icon: <StartRepeatSquare />,
                  handlers: [
                    {
                      icon: <FontAwesomeIcon icon={faTimesCircle} />,
                      handler: (): void => {
                        dispatch(deleteBarField("startBarline"));
                      }
                    },
                    {
                      icon: <StartDoubleSquare />,
                      handler: (): void => {
                        dispatch(updateBarField("startBarline", 1));
                      }
                    },
                    {
                      icon: <StartRepeatSquare />,
                      handler: (): void => {
                        dispatch(updateBarField("startBarline", 2));
                      }
                    },
                  ]
                },
                {
                  icon: <EndRepeatSquare />,
                  handlers: [
                    {
                      icon: <FontAwesomeIcon icon={faTimesCircle} />,
                      handler: (): void => {
                        dispatch(deleteBarField("endBarline"));
                      }
                    },
                    {
                      icon: <EndDoubleSquare />,
                      handler: (): void => {
                        dispatch(updateBarField("endBarline", 1));
                      }
                    },
                    {
                      icon: <EndRepeatSquare />,
                      handler: (): void => {
                        dispatch(updateBarField("endBarline", 2));
                      }
                    },
                    {
                      icon: <FinalSquare />,
                      handler: (): void => {
                        dispatch(updateBarField("endBarline", 3));
                      }
                    }
                  ]
                },
                {
                  icon: <FontAwesomeIcon icon={faHSquare} />,
                  title: "Toggle Section",
                  handler: (): void => {
                    dispatch(toggleBarField("section"));
                  }
                },
                {
                  icon: <FontAwesomeIcon icon={faCommentAlt} />,
                  title: "Toggle Comment",
                  handler: (): void => {
                    dispatch(toggleBarField("comment"));
                  }
                }
              ]
            ]),
          },
          {
            label: "Edit Chord",
            key: "2",
            children: PaneContent([
              [
                ...["7", "9", "11", "13"].map(n => ({
                  icon: n,
                  handler: (): void => {
                    dispatch(extendChord(n));
                  }
                })),
                {
                  title: "Clear Chord",
                  icon: <FontAwesomeIcon icon={faEraser} />,
                  handler: (): void => {
                    dispatch(clearChord());
                  }
                },
                {
                  title: "Backspace",
                  icon: <FontAwesomeIcon icon={faBackspace} />,
                  handler: (): void => {
                    dispatch(reduceChord());
                  }
                },
                isEditing || isTransposing
                  ? {
                    title: "Cancel Edits",
                    icon: (
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ color: "red" }}
                      />
                    ),
                    handler: (): void => {
                      dispatch(cancelEdits());
                    }
                  }
                  : {
                    title: "Previous Chord",
                    icon: <FontAwesomeIcon icon={faArrowCircleLeft} />,
                    handler: (): void => {
                      dispatch(prevChord());
                    }
                  },
                isEditing || isTransposing
                  ? {
                    title: "Save Edits",
                    icon: (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ color: "green" }}
                      />
                    ),
                    handler: (): void => {
                      dispatch(isEditing ? updateChord() : transpose());
                    }
                  }
                  : {
                    title: "Next Chord",
                    icon: <FontAwesomeIcon icon={faArrowCircleRight} />,
                    handler: (): void => {
                      dispatch(nextChord());
                    }
                  }
              ],
              ["Δ", "-", "+", "o", "ø", "sus", "♭", "♯", "/"].map(k => ({
                icon: k,
                handler: (): void => {
                  dispatch(extendChord(k));
                }
              })),
              ["A", "B", "C", "D", "E", "F", "G"].map(k => ({
                icon: k,
                handler: (): void => {
                  dispatch(extendChord(k));
                }
              }))
            ])
          },
        ]}
      >
      </Tabs>
    </Toolbar>
  );
};

export default EditableToolbar;
