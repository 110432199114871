import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Recaptcha from "react-recaptcha";
import { Form, Input, Button } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";
import { signupRequested } from "../../ducks/auth/actions";

let recaptchaInstance: any;

const Signup = () => {
  const [grecaptcha, setGrecaptcha] = useState(""); // TODO: use v3 captcha
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const onFinish = ({ username, email, password }: any): void => {
    recaptchaInstance.reset();
    setIsLoading(false);
    dispatch(signupRequested(username, email, password, grecaptcha));
  };

  // see https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
  const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  };

  return (
    <Form onFinish={onFinish} autoComplete="off">
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: ""
          },
          ({ getFieldValue }): any => ({
            validator: (): Promise<void> => {
              const username = getFieldValue("username");
              if (username.length < 5) {
                return Promise.reject(
                  new Error("Username needs to be at least 5 characters long")
                );
              }
              if (username.length > 30) {
                return Promise.reject(
                  new Error("Username needs to be at most 30 characters long")
                );
              }
              if (!username.match(/^[0-9a-z]*$/)) {
                return Promise.reject(
                  new Error("Username needs to be lowercase alphanumeric")
                );
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Input placeholder="Enter Username" prefix={<UserOutlined />} />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: ""
          },
          ({ getFieldValue }): any => ({
            validator: (): Promise<void> => {
              if (validateEmail(getFieldValue("email"))) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Email is not valid"));
            }
          })
        ]}
      >
        <Input placeholder="Enter Email" prefix={<MailOutlined />} />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: ""
          }
        ]}
      >
        <Input.Password
          placeholder="Enter Password"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Repeat Password"
        name="password2"
        rules={[
          {
            required: true,
            message: ""
          },
          ({ getFieldValue }): any => ({
            validator: (): Promise<void> => {
              if (getFieldValue("password") === getFieldValue("password2")) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords do not match"));
            }
          })
        ]}
      >
        <Input.Password
          placeholder="Enter Repeat Password"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Recaptcha
          sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
          ref={(e: Recaptcha): void => {
            recaptchaInstance = e;
          }}
          verifyCallback={(g: string): void => setGrecaptcha(g)}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ width: "100%" }}
        >
          Sign Up
        </Button>
        Already have an account? <Link to="/login">Log in here</Link>
      </Form.Item>
    </Form>
  );
};

export default Signup;
