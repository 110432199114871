import { generateQuickGuid } from "../../util";
import {
  expAck,
  recvAck,
  recvAck2,
  recvCurrentlyViewing,
  wsClose,
  wsOpen
} from "../../ducks/ws/actions";
import {
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT
} from "../../ducks/ws/constants";
import * as chartConstants from "../../ducks/chart/constants";
import * as playlistConstants from "../../ducks/playlist/constants";
import * as chartToWs from "./chart";
import * as playlistToWs from "./playlist";
import { AUTH, ACK, UPDATE_CURRENTLY_VIEWING, UPDATE_RESOURCE } from "./constants";
import { State } from "../../ducks";
import {
  WebsocketConnectAction,
  WebsocketDisconnectAction
} from "../../ducks/ws/types";
import { Dispatch, Middleware } from "redux";

const toWs = (type: string, state: State, params: any): {} | undefined => {
  switch (type) {
    case chartConstants.ADD_BAR: {
      return chartToWs.wsAddBar(state.chart);
    }
    case chartConstants.DELETE_BAR: {
      return chartToWs.wsDeleteBar(state.chart);
    }
    case chartConstants.INCREASE_BAR_WIDTH: {
      return chartToWs.wsIncreaseBarWidth(state.chart);
    }
    case chartConstants.DECREASE_BAR_WIDTH: {
      return chartToWs.wsDecreaseBarWidth(state.chart);
    }
    case chartConstants.UPDATE_NUMERATOR: {
      return chartToWs.wsUpdateNumerator(state.chart, params);
    }
    case chartConstants.UPDATE_DENOMINATOR: {
      return chartToWs.wsUpdateDenominator(state.chart, params);
    }
    case chartConstants.UPDATE_BAR_FIELD: {
      return chartToWs.wsUpdateBarField(state.chart, params);
    }
    case chartConstants.DELETE_BAR_FIELD: {
      return chartToWs.wsDeleteBarField(state.chart, params);
    }
    case chartConstants.TOGGLE_BAR_FIELD: {
      return chartToWs.wsToggleBarField(state.chart, params);
    }
    case chartConstants.UPDATE_CHORD: {
      return chartToWs.wsUpdateChord(state.chart);
    }
    case chartConstants.UPDATE_CHART_FIELD: {
      return chartToWs.wsUpdateChartField(params);
    }
    case chartConstants.TRANSPOSE: {
      return chartToWs.wsTranspose(state.chart);
    }
    case chartConstants.ADD_COLLABORATORS: {
      return chartToWs.wsAddCollaborators(params);
    }
    case playlistConstants.UPDATE_PLAYLIST_FIELD: {
      return playlistToWs.wsUpdatePlaylistField(params);
    }
    case playlistConstants.ADD_CHARTS: {
      return playlistToWs.wsAddCharts(params);
    }
    case playlistConstants.DELETE_CHART: {
      return playlistToWs.wsDeleteChart(state.playlist, params);
    }
    case playlistConstants.REORDER_CHARTS: {
      return playlistToWs.wsReorderCharts(params);
    }
    case playlistConstants.ADD_COLLABORATORS: {
      return playlistToWs.wsAddCollaborators(params);
    }
    default:
      return undefined;
  }
};

const createWebsocketMiddleware = (): Middleware => {
  let ws: any;

  const disconnect = (): void => {
    if (ws) {
      ws.close();
      ws = undefined;
    }
  };

  return ({
    getState,
    dispatch
  }: {
    getState: () => State;
    dispatch: Dispatch;
  }) => (next: any) => (
    action: WebsocketConnectAction | WebsocketDisconnectAction | { type: "" }
  ): void => {
    const state = getState(); // get state before calling next (reducer may change state)
    next(action); // call reducer (update the state) before dispatching/handling socket actions

    switch (action.type) {
      case WEBSOCKET_CONNECT: {
        disconnect();
        ws = new WebSocket(action.url);
        ws.onopen = (): void => {
          dispatch(wsOpen());
          ws.send(JSON.stringify({
            type: "AUTH",
            payload: { token: localStorage.getItem("access_token") },
          }));
        };
        ws.onclose = (): void => {
          dispatch(wsClose());
        };
        ws.onmessage = ({ data }: { data: any }): void => {
          const { type, payload } = JSON.parse(data);
          switch (type) {
            case AUTH: {
              // get initial data
              const guid = generateQuickGuid();
              dispatch(expAck(guid));
              ws.send(JSON.stringify({ type: "GET_DATA", guid }));
              break;
            }
            case ACK: {
              dispatch(recvAck(payload));
              break;
            }
            case UPDATE_CURRENTLY_VIEWING: {
              dispatch(recvCurrentlyViewing(payload));
              break;
            }
            case UPDATE_RESOURCE: {
              dispatch(action.recv(payload.resource));
              dispatch(recvAck2(payload.guid2));
              break;
            }
            default: {
              break;
            }
          }
        };
        break;
      }

      case WEBSOCKET_DISCONNECT: {
        disconnect();
        break;
      }

      default: {
        const { type, ...params } = action;
        const payload = toWs(type, state, params);
        if (payload && ws) {
          const guid = generateQuickGuid();
          dispatch(expAck(guid));
          ws.send(JSON.stringify({ ...payload, guid }));
        }
        break;
      }
    }
  };
};

export default createWebsocketMiddleware;
