export type Props = {
  field: "comment" | "section";
  value: string;
};

const AnnotationTextUneditable = ({ field, value }: Props) => (
  <div className={`tag annot ${field}`}>{value}</div>
);

export default AnnotationTextUneditable;
