import React, { useState } from "react";
import { Typography, Modal } from "antd";

const ModalSpan = ({
  className,
  field,
  value,
  title,
  onOk
}: {
  className: string;
  field: string;
  value: string;
  title: string;
  onOk: (v: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editingValue, setEditingValue] = useState(value);
  return (
    <>
      <span
        onClick={(): void => {
          setEditingValue(value);
          setIsOpen(true);
        }}
        className={`${className} clickable ${value === "" ? "empty" : ""}`}
      >
        {value === "" ? `<${field}>` : value}
      </span>
      {// rerendering modal is intentional - it is a hack to ensure that
      // autofocus input always triggers
      isOpen && (
        <Modal
          open={isOpen}
          title={title}
          onCancel={(): void => setIsOpen(false)}
          onOk={(): void => {
            onOk(editingValue);
            setIsOpen(false);
          }}
        >
          <Typography.Text strong>{`Enter ${field}:`}</Typography.Text>
          <input
            style={{ border: "none", outline: "none" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setEditingValue(e.target.value)
            }
            value={editingValue}
            autoFocus
          />
        </Modal>
      )}
    </>
  );
};

export default ModalSpan;
