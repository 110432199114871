import { useDispatch } from "react-redux";
import ModalSpan from "../../common/ModalSpan";
import { updateChartField } from "../../../ducks/chart/actions";

export type Props = {
  field: "title" | "style" | "artist";
  value: string;
};

const HeaderTextEditable = ({ field, value }: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalSpan
      className={`chart-${field}`}
      title="Chart Editor"
      field={field}
      value={value}
      onOk={(v: string): void => {
        dispatch(updateChartField(field, v));
      }}
    />
  );
};

export default HeaderTextEditable;
