// All icons from https://www.shareicon.net/pack/musical-symbols-and-annotations
export const Coda = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 210 210"
    xmlSpace="preserve"
  >
    <path
      d="M210,90h-28.801C175.145,59.186,150.814,34.855,120,28.8V0H90v28.8C59.186,34.855,34.855,59.186,28.801,90
    H0v30h28.801c6.055,30.814,30.385,55.145,61.199,61.2V210h30v-28.8c30.814-6.055,55.145-30.385,61.199-61.2H210V90z M150.235,90H120
    V59.765C134.234,64.498,145.502,75.766,150.235,90z M90,59.765V90H59.765C64.498,75.766,75.766,64.498,90,59.765z M59.765,120H90
    v30.235C75.766,145.502,64.498,134.234,59.765,120z M120,150.235V120h30.235C145.502,134.234,134.234,145.502,120,150.235z"
    />
  </svg>
);

export const Segno = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 260 260"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M164.494,116.719l79.071-79.071l-21.213-21.213l-98.511,98.511c-25.198-0.443-55.4-3.898-68.267-16.765
      c-11.697-11.697-11.697-30.729,0-42.427c3.859-3.859,8.52-6.436,13.448-7.749c-2.664,10.004-0.082,21.115,7.766,28.962
      c11.716,11.716,30.711,11.716,42.426,0c11.716-11.715,11.716-30.709,0-42.426h0.001c-23.395-23.395-61.461-23.394-84.854,0
      c-23.395,23.394-23.395,61.459,0,84.853c12.843,12.843,32.648,20.636,61.166,23.865l-79.094,79.094l21.213,21.213l98.51-98.51
      c52.03,0.895,63.909,12.763,67.911,16.764c12.291,12.292,12.292,30.136,0,42.428c-3.859,3.859-8.52,6.436-13.449,7.749
      c2.666-10.004,0.083-21.115-7.764-28.963c-11.716-11.716-30.711-11.716-42.427,0c-11.716,11.716-11.716,30.711,0,42.427
      c0.366,0.366,0.74,0.722,1.121,1.065c11.55,10.976,26.422,16.48,41.306,16.48c15.364,0,30.729-5.849,42.426-17.546
      c11.406-11.406,17.786-26.273,17.966-41.862c0.183-15.954-6.197-31.223-17.966-42.992
      C212.282,127.608,192.952,119.902,164.494,116.719z"
      />
      <circle cx="245" cy="100" r="15" />
      <circle cx="15" cy="160" r="15" />
    </g>
  </svg>
);
