import { useCallback, useState } from "react";

type TimeBarProps = { n: number; };

const TimeBar = (props: TimeBarProps) => {
  const [width, setWidth] = useState(0);
  const ref = useCallback((node: SVGSVGElement | null) => {
    if (node != null && node.parentElement != null) {
      setWidth(node.parentElement.getBoundingClientRect().width);
    }
  }, [])
  return (
    <svg
      ref={ref}
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox={`0 0 ${width} 12`}
      xmlSpace="preserve"
    >
      <g>
        <rect x="0" width={width} height="1" />
        <rect x="0" width="1" height="12" />
        <text x="5" y="12" fontSize="0.7rem">
          {props.n}.
        </text>
      </g>
    </svg>
  );
}

export default TimeBar;
