import type { MenuProps } from "antd";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { arrayMove } from "react-sortable-hoc";
import { Layout } from "antd";
import PlaylistHeader from "./PlaylistHeader";
import PlaylistList from "./PlaylistList";
import AddChartButton from "./AddChartButton";
import UneditableChart from "../chart/uneditable/UneditableChart";
import WsPageHeader from "../common/WsPageHeader";
import EditCollaboratorsModal from "../common/EditCollaboratorsModal";
import {
  deletePlaylistRequested,
  fetchPlaylistRequested,
  addCollaborators,
  reorderCharts
} from "../../ducks/playlist/actions";
import { reinit } from "../../ducks/common/actions";
import { wsDisconnect } from "../../ducks/ws/actions";
import { State } from "../../ducks";

const Playlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const playlistData = useSelector(
    (state: State) => state.playlist.playlistData
  );
  const currentChartID = useSelector(
    (state: State) => state.playlist.currentChartID
  );
  const isCollaborator = useSelector(
    (state: State) =>
      state.playlist.createdBy === state.auth.username ||
      state.playlist.collaborators.includes(state.auth.username)
  );
  const existingCollaborators = useSelector(
    (state: State) => state.playlist.collaborators
  );
  const createdBy = useSelector((state: State) => state.playlist.createdBy);
  const isOwner = useSelector(
    (state: State) => state.playlist.createdBy === state.auth.username
  );
  const title = useSelector((state: State) => state.playlist.title);
  const [isEditCollaboratorsModalOpen, setIsEditCollaboratorsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPlaylistRequested(id!));
    return (): void => {
      dispatch(wsDisconnect());
      dispatch(reinit());
    };
  }, [id, dispatch]);

  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    const newPlaylistData = arrayMove(playlistData, oldIndex, newIndex);
    dispatch(reorderCharts(newPlaylistData));
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: 'Edit Collaborators',
      key: 'editcollaborators',
      disabled: !isCollaborator,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      onClick: () => setIsEditCollaboratorsModalOpen(true),
    },
    {
      label: 'Delete Playlist',
      key: 'delete',
      disabled: !isOwner,
      icon: <FontAwesomeIcon icon={faTrash} />,
      onClick: () => {
        Modal.confirm({
          title: "Confirm Deletion",
          content: "Are you sure you want to delete?",
          onOk: () => dispatch(deletePlaylistRequested(id!, navigate)),
        })
      },
    }
  ]

  return (
    <>
      <WsPageHeader
        isCollaborator={isCollaborator}
        dropdownItems={dropdownItems}
      />
      <EditCollaboratorsModal
        createdBy={createdBy}
        existingCollaborators={existingCollaborators}
        onAdd={(newCollaborators: Array<string>) =>
          dispatch(addCollaborators(newCollaborators))}
        isOpen={isEditCollaboratorsModalOpen}
        setIsOpen={setIsEditCollaboratorsModalOpen}
      />
      <div className="playlist-container">
        <Layout>
          <Layout.Content style={{ display: "flex" }}>
            <div className="chart-container">
              {currentChartID === "" ? (
                <div className="chart-nottoolbar" />
              ) : (
                <UneditableChart />
              )}
            </div>
          </Layout.Content>
          <Layout.Sider
            breakpoint="md"
            collapsedWidth={0}
            reverseArrow={true}
            zeroWidthTriggerStyle={{ opacity: "70%" }}
            style={{
              backgroundColor: "white",
              boxShadow: "2px 0px 0px 0px whitesmoke inset"
            }}
          >
            <div style={{ height: "100%", overflowY: "auto" }}>
              <PlaylistHeader title={title} />
              {!(playlistData.length > 0) ? (
                <div>No charts added yet!</div>
              ) : (
                <PlaylistList
                  onSortEnd={onSortEnd}
                  lockAxis="y"
                  useDragHandle
                  helperClass="sortableHelper"
                />
              )}
              <AddChartButton />
            </div>
          </Layout.Sider>
        </Layout>
      </div>
    </>
  );
};

export default Playlist;
