import {
  takeLatest,
  call,
  put,
  select,
  fork,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect
} from "redux-saga/effects";
import { message } from "antd";
import {
  fetchPlaylistAPI,
  addPlaylistAPI,
  deletePlaylistAPI
} from "../../api/playlist";
import { fetchChartAPI } from "../../api/chart";
import { fetchChartSucceeded, fetchChartFailed } from "../chart/actions";
import { captureException } from "../../util/sentry";
import * as constants from "./constants";
import * as actions from "./actions";
import * as types from "./types";
import * as wsTypes from "../ws/types";
import * as chartTypes from "../chart/types";
import { wsConnectPlaylist } from "../ws/actions";
import { Chart, Playlist } from "../../types";
import { State } from "../../ducks";

function* fetchPlaylist({
  playlistID
}: types.FetchPlaylistRequestedAction): Generator<
  | CallEffect<Playlist>
  | PutEffect<types.FetchPlaylistSucceededActon>
  | PutEffect<types.FetchChartRequestedAction>
  | SelectEffect
  | PutEffect<wsTypes.WebsocketConnectAction>
  | PutEffect<types.FetchPlaylistFailedAction>
> {
  try {
    const res: any = yield call(fetchPlaylistAPI, playlistID);
    yield put(actions.fetchPlaylistSucceeded(res));
    const { playlistData, collaborators } = res;
    // if there is at least one data in the playlist, load the first one
    if (playlistData.length > 0)
      yield put(actions.fetchChartRequested(playlistData[0]));
    // set up websocket if user is a collaborator
    const isCollaborator = yield select((state: State) =>
      collaborators.includes(state.auth.username)
    );
    if (isCollaborator) yield put(wsConnectPlaylist(playlistID));
  } catch (e) {
    yield put(actions.fetchPlaylistFailed());
    captureException(e);
  }
}

function* fetchChart({
  chartID
}: types.FetchChartRequestedAction): Generator<
  | CallEffect<Chart>
  | PutEffect<chartTypes.FetchChartSucceededAction>
  | PutEffect<chartTypes.FetchChartFailedAction>
> {
  try {
    const res: any = yield call(fetchChartAPI, chartID);
    yield put(fetchChartSucceeded(res)); // stop here (do not set up websocket)
  } catch (e) {
    yield put(fetchChartFailed());
    captureException(e);
  }
}

function* addPlaylist({
  navigate
}: types.AddPlaylistRequestedAction): Generator<
  | CallEffect<{ playlistID: string }>
  | PutEffect<types.AddPlaylistSucceededAction>
  | PutEffect<types.AddPlaylistFailedAction>
> {
  try {
    const { playlistID }: any = yield call(addPlaylistAPI);
    yield put(actions.addPlaylistSucceeded());
    navigate(`/playlists/${playlistID}`);
    message.success("Successfully added playlist!");
  } catch (e) {
    yield put(actions.addPlaylistFailed());
    captureException(e);
  }
}

function* deletePlaylist({
  playlistID,
  navigate
}: types.DeletePlaylistRequestedAction): Generator<
  | CallEffect<Response>
  | PutEffect<types.DeletePlaylistSucceededAction>
  | PutEffect<types.DeletePlaylistFailedAction>
> {
  try {
    const { status }: any = yield call(deletePlaylistAPI, playlistID);
    if (status === 200) {
      yield put(actions.deletePlaylistSucceeded());
      navigate("/");
      message.success("Successfully deleted playlist!");
    } else {
      yield put(actions.deletePlaylistFailed());
      message.error("Failed to delete playlist");
    }
  } catch (e) {
    yield put(actions.deletePlaylistFailed());
    captureException(e);
  }
}

function* fetchPlaylistSaga(): Generator<
  ForkEffect<types.FetchPlaylistRequestedAction>
> {
  yield takeLatest(constants.FETCH_PLAYLIST_REQUESTED, fetchPlaylist);
}
function* fetchChartSaga(): Generator<
  ForkEffect<types.FetchChartRequestedAction>
> {
  yield takeLatest(constants.FETCH_CHART_REQUESTED, fetchChart);
}
function* addPlaylistSaga(): Generator<
  ForkEffect<types.AddPlaylistRequestedAction>
> {
  yield takeLatest(constants.ADD_PLAYLIST_REQUESTED, addPlaylist);
}
function* deletePlaylistSaga(): Generator<
  ForkEffect<types.DeletePlaylistRequestedAction>
> {
  yield takeLatest(constants.DELETE_PLAYLIST_REQUESTED, deletePlaylist);
}

const a = [
  fork(fetchPlaylistSaga),
  fork(fetchChartSaga),
  fork(addPlaylistSaga),
  fork(deletePlaylistSaga)
];
export default a;
