import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Table } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { getDateFromString } from "../../util";
import { captureException } from "../../util/sentry";
import { fetchYourPlaylistsAPI } from "../../api/main";
import { Playlist } from "../../types";

const YourPlaylists = () => {
  const [playlists, setPlaylists] = useState<Array<Playlist>>([]);
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    fetchYourPlaylistsAPI()
      .then(playlists => {
        setPlaylists(playlists);
        setIsFetching(false);
      })
      .catch(e => {
        setPlaylists([]);
        setIsFetching(false);
        captureException(e);
      });
  }, [setPlaylists, setIsFetching]);
  const columns = [
    {
      title: "Title",
      key: "title",
      render: (playlist: Playlist) => (
        <Link to={`/playlists/${playlist.id}`}>
          {playlist.title === "" ? "Untitled Playlist" : playlist.title}
        </Link>
      )
    },
    {
      title: "Date Created",
      key: "dateCreated",
      render: (playlist: Playlist): string =>
        getDateFromString(playlist.dateCreated)
    },
    {
      title: "Last Updated",
      key: "lastUpdated",
      render: (playlist: Playlist): string =>
        getDateFromString(playlist.lastUpdated)
    },
    {
      title: "Number of Charts",
      key: "numberOfCharts",
      render: (playlist: Playlist): number => playlist.playlistData.length,
      responsive: ["md"] as Breakpoint[]
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      responsive: ["md"] as Breakpoint[]
    }
  ];

  return (
    <>
      <Typography.Title>Your Playlists</Typography.Title>
      <Table
        pagination={false}
        columns={columns}
        dataSource={playlists}
        loading={isFetching}
        rowKey="id"
      />
    </>
  );
};

export default YourPlaylists;
