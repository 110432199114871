import { useDispatch, useSelector } from "react-redux";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { HolderOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import { PlaylistItem as PlaylistItemType } from "../../types";
import { State } from "../../ducks";
import { deleteChart, fetchChartRequested } from "../../ducks/playlist/actions";
import * as types from "../../ducks/playlist/types";

const DragHandle = SortableHandle(() => (
  <HolderOutlined className="playlist-drag-handle" />
));

interface Props {
  data: PlaylistItemType;
  index: number; // from sortable element
  pressDelay: number; // from sortable element
}

const PlaylistItem = (props: Props) => {
  const currentChartID = useSelector(
    (state: State) => state.playlist.currentChartID
  );
  const dispatch = useDispatch();
  return (
    <li className="playlist-item">
      <DragHandle />
      <a
        href="#/"
        onClick={(): types.FetchChartRequestedAction =>
          dispatch(fetchChartRequested(props.data.id))
        }
        className={`playlist-chart-item${
          currentChartID === props.data.id ? " is-active" : ""
          }`}
        role="presentation"
      >
        {props.data.title === "" ? "Untitled Chart" : props.data.title}
        &nbsp; (
        {props.data.artist === "" ? "Unknown Artist" : props.data.artist})
      </a>
      <CloseCircleTwoTone
        className="playlist-chart-delete"
        onClick={(): types.DeleteChartAction =>
          dispatch(deleteChart(props.data.id))
        }
      />
    </li>
  );
};

export default SortableElement<Props>(PlaylistItem);
