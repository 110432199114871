export const request = (
  path: string,
  params: {
    method?: "get" | "post";
    body?: object;
    auth?: boolean;
  } = {}
): Promise<Response> => {
  const { method = "get", body = undefined, auth = false } = params;
  const options: RequestInit = { method };

  // optional headers
  const headers: HeadersInit = {};
  if (auth)
    headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
  if (body) headers["Content-Type"] = "application/json";
  if (Object.entries(headers).length > 0) options.headers = headers;

  // optional body
  if (body) options.body = JSON.stringify(body);

  return fetch(`${process.env.REACT_APP_API_URL}${path}`, options);
};

export const parseJsonResponse = (response: Response): Promise<any> => {
  if (response.status >= 200 && response.status < 300) return response.json();
  return new Promise((_, reject) =>
    reject(new Error("Something seems wrong..."))
  );
};
