import { NavigateFunction } from "react-router-dom";
import { chordExtensionMap } from "../../constants/chord";
import { Chart, ToggleableBarField } from "../../types";
import * as constants from "./constants";
import * as types from "./types";

export const fetchChartRequested = (
  chartID: string
): types.FetchChartRequestedAction => ({
  type: constants.FETCH_CHART_REQUESTED,
  chartID
});

export const fetchChartSucceeded = (
  chart: Chart
): types.FetchChartSucceededAction => ({
  type: constants.FETCH_CHART_SUCCEEDED,
  chart
});

export const fetchChartFailed = (): types.FetchChartFailedAction => ({
  type: constants.FETCH_CHART_FAILED
});

export const cloneChartRequested = (
  chartID: string,
  navigate: NavigateFunction
): types.CloneChartRequestedAction => ({
  type: constants.CLONE_CHART_REQUESTED,
  chartID,
  navigate,
});

export const cloneChartSucceeded = (): types.CloneChartSucceededAction => ({
  type: constants.CLONE_CHART_SUCCEEDED
});

export const cloneChartFailed = (): types.CloneChartFailedAction => ({
  type: constants.CLONE_CHART_FAILED
});

export const addChartRequested = (
  navigate: NavigateFunction
): types.AddChartRequestedAction => ({
  type: constants.ADD_CHART_REQUESTED,
  navigate,
});

export const addChartSucceeded = (): types.AddChartSucceededAction => ({
  type: constants.ADD_CHART_SUCCEEDED
});

export const addChartFailed = (): types.AddChartFailedAction => ({
  type: constants.ADD_CHART_FAILED
});

export const deleteChartRequested = (
  chartID: string,
  navigate: NavigateFunction
): types.DeleteChartRequestedAction => ({
  type: constants.DELETE_CHART_REQUESTED,
  chartID,
  navigate
});

export const deleteChartSucceeded = (): types.DeleteChartSucceededAction => ({
  type: constants.DELETE_CHART_SUCCEEDED
});

export const deleteChartFailed = (): types.DeleteChartFailedAction => ({
  type: constants.DELETE_CHART_FAILED
});

export const toggleToolbar = (): types.ToggleToolbarAction => ({
  type: constants.TOGGLE_TOOLBAR
});

export const setCurrentBar = (barID: string): types.SetCurrentBarAction => ({
  type: constants.SET_CURRENTBAR,
  barID
});

export const setCurrentChord = (
  chordPosition: number,
  barID: string
): types.SetCurrentChordAction => ({
  type: constants.SET_CURRENTCHORD,
  chordPosition,
  barID
});

export const cancelEdits = (): types.CancelEditsAction => ({
  type: constants.CANCEL_EDITS
});

export const transposeUp = (): types.TransposeUpAction => ({
  type: constants.TRANSPOSE_UP
});

export const transposeDown = (): types.TransposeDownAction => ({
  type: constants.TRANSPOSE_DOWN
});

export const prevChord = (): types.SetPrevChordAction => ({
  type: constants.SET_PREVCHORD
});

export const nextChord = (): types.SetNextChordAction => ({
  type: constants.SET_NEXTCHORD
});

export const extendChord = (k: string): types.ExtendChordAction => ({
  type: constants.EXTEND_CHORD,
  extend: chordExtensionMap[k]
});

export const reduceChord = (): types.ReduceChordAction => ({
  type: constants.REDUCE_CHORD
});

export const clearChord = (): types.ClearChordAction => ({
  type: constants.CLEAR_CHORD
});

export const addBar = (): types.AddBarAction => ({
  type: constants.ADD_BAR
});

export const deleteBar = (): types.DeleteBarAction => ({
  type: constants.DELETE_BAR
});

export const incBarWidth = (): types.IncreaseBarWidthAction => ({
  type: constants.INCREASE_BAR_WIDTH
});

export const decBarWidth = (): types.DecreaseBarWidthAction => ({
  type: constants.DECREASE_BAR_WIDTH
});

export const updateNumerator = (n: number): types.UpdateNumeratorAction => ({
  type: constants.UPDATE_NUMERATOR,
  n
});

export const updateDenominator = (
  d: number
): types.UpdateDenominatorAction => ({
  type: constants.UPDATE_DENOMINATOR,
  d
});

export const updateBarField = (
  field: string,
  value: string | number
): types.UpdateBarFieldAction => ({
  type: constants.UPDATE_BAR_FIELD,
  field,
  value
});

export const deleteBarField = (field: string): types.DeleteBarFieldAction => ({
  type: constants.DELETE_BAR_FIELD,
  field
});

export const toggleBarField = (
  field: ToggleableBarField
): types.ToggleBarFieldAction => ({
  type: constants.TOGGLE_BAR_FIELD,
  field
});

export const updateChartField = (
  field: string,
  value: string
): types.UpdateChartFieldAction => ({
  type: constants.UPDATE_CHART_FIELD,
  field,
  value
});

export const updateChord = (): types.UpdateChordAction => ({
  type: constants.UPDATE_CHORD
});

export const transpose = (): types.TransposeAction => ({
  type: constants.TRANSPOSE
});

export const addCollaborators = (
  collaborators: Array<string>
): types.AddCollaboratorsAction => ({
  type: constants.ADD_COLLABORATORS,
  collaborators
});

export const recvChart = (chart: Chart): types.RecvChartAction => ({
  type: constants.RECV_CHART,
  chart
});
