import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Layout, Drawer, Menu, Dropdown, Button, Row, Col } from "antd";
import {
  MenuOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  PlusOutlined,
  ProfileOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserAddOutlined,
  UserOutlined,
  DownOutlined
} from "@ant-design/icons";
import { State } from "../../ducks";
import { logoutRequested } from "../../ducks/auth/actions";
import { addPlaylistRequested } from "../../ducks/playlist/actions";
import { addChartRequested } from "../../ducks/chart/actions";

interface Props {
  children: React.ReactNode;
}

const Nav = (props: Props) => {
  const [open, setOpen] = useState(false);
  const username = useSelector((state: State) => state.auth.username);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dropdownItems = [
    {
      label:"New Chart",
      icon:<PlusOutlined />,
      key: "newchart",
      onClick:() => {
        dispatch(addChartRequested(navigate));
        setOpen(false);
      },
    },
    {
      label: "New Playlist",
      icon: <PlusOutlined />,
      key: "newplaylist",
      onClick: () => {
        dispatch(addPlaylistRequested(navigate));
        setOpen(false);
      },
    },
    {
      label: "Log Out",
      icon: <LogoutOutlined />,
      key: "logout",
      onClick: () => {
        dispatch(logoutRequested(navigate));
        setOpen(false);
      },
    },
  ];

  const itemSearch = {
    label: <Link to="/search">Search Charts</Link>,
    icon: <FileSearchOutlined />,
    key: "search",
    onClick: () => {
      setOpen(false);
    },
  }

  const itemSignup = {
    label: <Link to="/signup">Sign Up</Link>,
    icon: <UserAddOutlined />,
    key: "signup",
    onClick: () => {
      setOpen(false);
    },
  }

  const itemLogin = {
    label: <Link to="/login">Log In</Link>,
    icon: <LoginOutlined />,
    key: "login",
    onClick: () => {
      setOpen(false);
    },
  }

  const itemCharts = {
    label: <Link to="/charts">Your Charts</Link>,
    icon: <FileTextOutlined />,
    key: "charts",
    onClick: () => {
      setOpen(false);
    },
  }

  const itemPlaylists = {
    label: <Link to="/playlists">Your Playlists</Link>,
    icon: <ProfileOutlined />,
    key: "playlists",
    onClick: () => {
      setOpen(false);
    },
  }

  const drawerItems = username ===  "" ? [
    itemSearch,
    itemSignup,
    itemLogin,
  ] : [
    itemSearch,
    itemCharts,
    itemPlaylists,
  ]

  return (
    <Layout>
      <Drawer
        placement="left"
        open={open}
        closable={false}
        onClose={(): void => setOpen(false)}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="inline" items={drawerItems} />
      </Drawer>
      <Layout style={{ height: "100vh", display: "flex" }}>
        <Layout.Header style={{ padding: 0 }}>
          <Row>
            <Col>
              <MenuOutlined
                style={{
                  color: "whitesmoke",
                  fontSize: 24,
                  verticalAlign: "middle",
                  marginLeft: 12
                }}
                onClick={(): void => setOpen(!open)}
              />
            </Col>
            {username !== "" && (
              <>
                <Col flex="auto" />
                <Col>
                  <Dropdown
                    trigger={["click"]}
                    arrow
                    menu={{ items: dropdownItems }}
                    placement="bottomRight"
                  >
                    <Button style={{ marginRight: 12 }}>
                      <UserOutlined />
                      {username}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </>
            )}
          </Row>
        </Layout.Header>
        <Layout.Content
          style={{
            flexGrow: 1,
            overflowY: "auto"
          }}
        >
          <Row justify="center" style={{ marginTop: 12 }}>
            <Col xs={22} sm={20} md={18} lg={16}>
              {props.children}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default Nav;
