import jwtDecode from "jwt-decode";
import { refreshTokenAPI } from "../api/main";

const isTokenValid = (jwt: string): boolean => {
  try {
    // jwtDecode throws if jwt is invalid
    const { exp } = jwtDecode<{exp: number}>(jwt);
    const now = Date.now() * 1e6; // milliseconds -> nanoseconds
    return exp > now;
  } catch {
    return false;
  }
};

export const initToken = (): void => {
  const key = "access_token";
  const token = localStorage.getItem(key) ?? "";
  if (isTokenValid(token)) {
    refreshTokenAPI()
      .then(({ jwt }) => localStorage.setItem(key, jwt))
      .catch(() => localStorage.removeItem(key));
  } else {
    localStorage.removeItem(key);
  }
};
