export const ADD_CHARTS = "playlist/ADD_CHARTS";
export const ADD_COLLABORATORS = "playlist/ADD_COLLABORATORS";
export const ADD_PLAYLIST_FAILED = "playlist/ADD_PLAYLIST_FAILED";
export const ADD_PLAYLIST_REQUESTED = "playlist/ADD_PLAYLIST_REQUESTED";
export const ADD_PLAYLIST_SUCCEEDED = "playlist/ADD_PLAYLIST_SUCCEEDED";
export const DELETE_CHART = "playlist/DELETE_CHART";
export const DELETE_PLAYLIST_FAILED = "playlist/DELETE_PLAYLIST_FAILED";
export const DELETE_PLAYLIST_REQUESTED = "playlist/DELETE_PLAYLIST_REQUESTED";
export const DELETE_PLAYLIST_SUCCEEDED = "playlist/DELETE_PLAYLIST_SUCCEEDED";
export const FETCH_CHART_REQUESTED = "playlist/FETCH_CHART_REQUESTED";
export const FETCH_PLAYLIST_FAILED = "playlist/FETCH_PLAYLIST_FAILED";
export const FETCH_PLAYLIST_REQUESTED = "playlist/FETCH_PLAYLIST_REQUESTED";
export const FETCH_PLAYLIST_SUCCEEDED = "playlist/FETCH_PLAYLIST_SUCCEEDED";
export const RECV_PLAYLIST = "playlist/RECV_PLAYLIST";
export const REORDER_CHARTS = "playlist/REORDER_CHARTS";
export const UPDATE_PLAYLIST_FIELD = "playlist/UPDATE_PLAYLIST_FIELD";
