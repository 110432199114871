import { useDispatch, useSelector } from "react-redux";
import Coda from "../barDetail/Coda";
import AnnotationTextEditableView from "../barDetail/AnnotationTextEditable";
import TimeBarView from "../barDetail/TimeBar";
import { StartBarline, EndBarline } from "../barDetail/Barline";
import TimeSignatureView from "../barDetail/TimeSignature";
import Chord from "../barDetail/Chord";
import { setCurrentBar, setCurrentChord } from "../../../ducks/chart/actions";
import * as types from "../../../ducks/chart/types";
import { Bar as BarType } from "../../../types";
import { State } from "../../../ducks";

interface Props {
  barData: BarType;
}

const Bar = ({ barData }: Props) => {
  const {
    coda,
    section,
    comment,
    timeBar,
    startBarline,
    endBarline,
    barWidth,
    barData: chords,
    denominator,
    showTS,
    id
  } = barData;
  const dispatch = useDispatch();
  const viewBar = (barID: string): types.SetCurrentBarAction =>
    dispatch(setCurrentBar(barID));
  const viewChord = (
    chordPosition: number,
    barID: string
  ): types.SetCurrentChordAction =>
    dispatch(setCurrentChord(chordPosition, barID));
  const unitsPerBar = useSelector(
    (state: State) => state.chart.unitsPerRow / state.chart.barsPerRow
  );
  const currentChordPosition = useSelector(
    (state: State) => state.chart.currentChordPosition
  );
  const currentBarID = useSelector((state: State) => state.chart.currentBarID);
  const editingChord = useSelector((state: State) => state.chart.editingChord);
  const isEditing = useSelector((state: State) => state.chart.isEditing);
  return (
    <div
      className={`bar is-${String(unitsPerBar * barWidth)}${
        id === currentBarID ? " highlighted" : ""
        }`}
      key={id}
      onClick={(): types.SetCurrentBarAction => viewBar(id)}
      role="presentation"
    >
      {typeof coda === "number" && <Coda symbol={coda} />}
      {typeof section === "string" && (
        <AnnotationTextEditableView
          field="section"
          value={section}
          barID={id}
        />
      )}
      {typeof comment === "string" && (
        <AnnotationTextEditableView
          field="comment"
          value={comment}
          barID={id}
        />
      )}
      {typeof timeBar === "number" && <TimeBarView n={timeBar} />}
      {typeof startBarline === "number" && (
        <StartBarline barline={startBarline} />
      )}
      {showTS && (
        <TimeSignatureView
          numerator={chords.length.toString()}
          denominator={denominator.toString()}
        />
      )}
      {chords.map((chord, i) => (
        <Chord
          key={i}
          highlighted={currentChordPosition === i && currentBarID === id}
          chord={
            currentChordPosition === i && currentBarID === id && isEditing
              ? editingChord
              : chord
          }
          onClick={(): types.SetCurrentChordAction => viewChord(i, id)}
        />
      ))}
      {typeof endBarline === "number" && <EndBarline barline={endBarline} />}
    </div>
  );
};

export default Bar;
