export const LOGIN_REQUESTED = "auth/LOGIN_REQUESTED";
export const LOGIN_SUCCEEDED = "auth/LOGIN_SUCCEEDED";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const INITRESETPASSWORD_REQUESTED = "auth/INITRESETPASSWORD_REQUESTED";
export const INITRESETPASSWORD_SUCCEEDED = "auth/INITRESETPASSWORD_SUCCEEDED";
export const INITRESETPASSWORD_FAILED = "auth/INITRESETPASSWORD_FAILED";
export const RESETPASSWORD_REQUESTED = "auth/RESETPASSWORD_REQUESTED";
export const RESETPASSWORD_SUCCEEDED = "auth/RESETPASSWORD_SUCCEEDED";
export const RESETPASSWORD_FAILED = "auth/RESETPASSWORD_FAILED";
export const LOGOUT_REQUESTED = "auth/LOGOUT_REQUESTED";
export const LOGOUT_SUCCEEDED = "auth/LOGOUT_SUCCEEDED";
export const SIGNUP_REQUESTED = "auth/SIGNUP_REQUESTED";
export const SIGNUP_FAILED = "auth/SIGNUP_FAILED";
export const ACTIVATEACCOUNT_REQUESTED = "auth/ACTIVATEACCOUNT_REQUESTED";
export const ACTIVATEACCOUNT_SUCCEEDED = "auth/ACTIVATEACCOUNT_SUCCEEDED";
export const ACTIVATEACCOUNT_FAILED = "auth/ACTIVATEACCOUNT_FAILED";
