export type Props = {
  field: "title" | "style" | "artist";
  value: string;
};

const HeaderTextUneditable = ({ field, value }: Props) => (
  <span className={`chart-${field}`}>{value}</span>
);

export default HeaderTextUneditable;
