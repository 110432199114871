import { useState, useEffect } from "react";
import { Modal, Space, Tag, Select, Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { captureException } from "../../util/sentry";
import { fetchUsersAPI } from "../../api/main";

type Props = {
  createdBy: string;
  existingCollaborators: Array<string>;
  onAdd: (newCollaborators: Array<string>) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const EditCollaboratorsModal = ({
  createdBy,
  existingCollaborators,
  onAdd,
  isOpen,
  setIsOpen,
}: Props) => {
  const [userSearchInputValue, setUserSearchInputValue] = useState("");
  const [userSearchResultsValue, setUserSearchResultsValue] = useState<Array<string>>([]);
  const [newCollaborators, setNewCollaborators] = useState<Array<string>>([]);

  useEffect(() => {
    const exclude = existingCollaborators.concat(newCollaborators);
    fetchUsersAPI(exclude, userSearchInputValue)
      .then(({ suggestions }: { suggestions: Array<string> }) =>
        setUserSearchResultsValue(suggestions.filter(s => s !== createdBy))
      )
      .catch(e => captureException(e));
  }, [
    userSearchInputValue,
    existingCollaborators,
    newCollaborators,
    createdBy
  ]);

  return (
    <Modal
      title="Edit Collaborators"
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      {`${existingCollaborators.length} existing collaborator${existingCollaborators.length !== 1 ? "s" : ""
        }:`}
      <Space
        wrap
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          marginTop: 12,
          marginBottom: 12,
          padding: 12
        }}
      >
        <Tag color="success" key={createdBy}>
          {`${createdBy} (owner)`}
        </Tag>
        {existingCollaborators
          .filter(c => c !== createdBy)
          .map(c => (
            <Tag color="success" key={c}>
              {c}
            </Tag>
          ))}
      </Space>
      <Row style={{ width: "100%" }}>
        <Col flex="auto">
          <Select
            mode="multiple"
            allowClear
            placeholder="add more users"
            onChange={v => setNewCollaborators(v)}
            onSearch={v => setUserSearchInputValue(v)}
            style={{ width: "100%" }}
          >
            {userSearchResultsValue.map((u, i) => (
              <Select.Option key={i} value={u}>
                {u}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button
            icon={<FontAwesomeIcon icon={faUserPlus} />}
            onClick={() => onAdd(newCollaborators)}
          />
        </Col>
      </Row>
    </Modal>
  )
};

export default EditCollaboratorsModal;
