import { useDispatch } from "react-redux";
import ModalSpan from "../common/ModalSpan";
import { updatePlaylistField } from "../../ducks/playlist/actions";

interface Props {
  title: string;
}

const PlaylistHeader = ({ title }: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalSpan
      className="playlist-title"
      title="Playlist Editor"
      field="title"
      value={title}
      onOk={(v: string): void => {
        dispatch(updatePlaylistField("title", v));
      }}
    />
  );
};

export default PlaylistHeader;
