import { sharpMap, flatMap } from "../constants/chord";
import { Bar } from "../types";

// isValid checks if a chord is in a valid format
// chords come in 3 parts: a "root", an "extension", and an "over"
// "roots" can be any of A-G, with an optional #/b
// "extensions" can consist of any number of typable toolbar characters, but
// cannot begin with a #/b (to prevent splitChord confusion)
// "overs" begin with a slash, follow by any of A-G, with an optional #/b
export const isValid = (chord: string): boolean =>
  /^(([A-G][#b]?)((?![#b])[\^\-+ohsu#b135679]*)?)?(\/[A-G][#b]?)?$/g.test(
    chord
  );

// setShowTS sets a showTS boolean value for each bar in some chart data
export const setShowTS = (chartData: Array<Bar>): Array<Bar> =>
  chartData.map((v, i, a) => ({
    ...v,
    showTS: !!(
      (
        i === 0 || // if first bar
        a[i].barData.length !== a[i - 1].barData.length || // if numerator is different in prevBar
        a[i].denominator !== a[i - 1].denominator
      ) // if denominator is different in prevBar
    )
  }));

// chunk splits bars into rows based on bar width
export const chunk = (
  chartData: Array<Bar>,
  width: number
): Array<Array<Bar>> => {
  const ret = [];
  let tmp: Array<Bar> = [];
  let tmpWidth = 0;
  for (let i = 0; i < chartData.length; i += 1) {
    const bar = chartData[i];
    // prevent total row width from exceeding defined width
    tmpWidth += bar.barWidth;
    if (tmpWidth > width) {
      ret.push(tmp);
      tmp = [bar];
      tmpWidth = bar.barWidth;
    } else {
      tmp.push(bar);
    }
    // push last chunk on final iteration
    if (i === chartData.length - 1) {
      ret.push(tmp);
    }
  }
  return ret;
};

// hasTop checks for any annotations present above a given row
export const hasTop = (arr: Array<Bar>): boolean => {
  for (let i = 0; i < arr.length; i += 1) {
    const { section, coda, timeBar } = arr[i];
    if (section != null || coda != null || timeBar != null) {
      return true;
    }
  }
  return false;
};

// hasBtm checks for any annotations present below a given row
export const hasBtm = (arr: Array<Bar>): boolean => {
  for (let i = 0; i < arr.length; i += 1) {
    const { comment } = arr[i];
    if (typeof comment === "string") {
      return true;
    }
  }
  return false;
};

// split chord splits a chord into its components
export const splitChord = (
  chord: string
): { base: string; mod: string; over: string } => {
  const spl = chord.split("/");
  const modspl = spl[0][1] === "#" || spl[0][1] === "b" ? 2 : 1;
  return {
    base: spl[0].substring(0, modspl),
    mod: spl[0].substring(modspl),
    over: spl.length > 1 ? spl[1] : ""
  };
};

// extendMapNTimes returns a sharp/flat map that transposes a note by n semitones
export const extendMapNTimes = (
  map: { [key: string]: string },
  n: number
): { [key: string]: { [key: string]: string } } => {
  const ret: { [key: string]: { [key: string]: string } } = {};
  Object.keys(map).forEach(note => {
    ret[note] = {};
    ret[note][0] = note; // base case
    for (let i = 1; i <= n; i += 1) {
      ret[note][i] = map[ret[note][i - 1]];
    }
  });
  return ret;
};
const sharpMapN: { [key: string]: { [key: string]: string } } = extendMapNTimes(
  sharpMap,
  11
);
const flatMapN: { [key: string]: { [key: string]: string } } = extendMapNTimes(
  flatMap,
  11
);

// chordUp transposes a chord up by n semitones
export const chordUp = (chord: string, n: number): string => {
  if (!isValid(chord)) return chord;
  const { base, mod, over } = splitChord(chord);
  return (
    sharpMapN[base][n] + mod + (over === "" ? "" : `/${sharpMapN[over][n]}`)
  );
};

// chordDown transposes a chord down by n semitones
export const chordDown = (chord: string, n: number): string => {
  if (!isValid(chord)) return chord;
  const { base, mod, over } = splitChord(chord);
  return flatMapN[base][n] + mod + (over === "" ? "" : `/${flatMapN[over][n]}`);
};

// transpose transposes some chart data by n semitones
export const transpose = (chartData: Array<Bar>, n: number): Array<Bar> =>
  chartData.map(bar => ({
    ...bar,
    barData: bar.barData.map(chord =>
      n === 0
        ? chord
        : n > 0
          ? chordUp(chord, n)
          : chordDown(chord, Math.abs(n))
    )
  }));
