import { request, parseJsonResponse } from "./request";
import { Playlist } from "../types";

const fetchPlaylistAPI = (playlistID: string): Promise<Playlist> =>
  request(`/api/playlists/${playlistID}`).then(parseJsonResponse);

const addPlaylistAPI = (): Promise<{ playlistID: string }> =>
  request("/api/addplaylist", {
    method: "post",
    auth: true
  }).then(parseJsonResponse);

const deletePlaylistAPI = (playlistID: string): Promise<Response> =>
  request("/api/deleteplaylist", {
    method: "post",
    body: { playlistID },
    auth: true
  });

export { fetchPlaylistAPI, addPlaylistAPI, deletePlaylistAPI };
