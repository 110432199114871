import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input, Table } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { getDateFromString } from "../../util";
import { captureException } from "../../util/sentry";
import { fetchChartsAPI } from "../../api/main";
import { Chart } from "../../types";
import { State } from "../../ducks";
import { useThrottle } from "../../util/hooks";

const Charts = () => {
  const username = useSelector((state: State) => state.auth.username);
  const [charts, setCharts] = useState<Array<Chart>>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [term, setTerm] = useState("");
  const columns = [
    {
      title: "Title",
      key: "title",
      render: (chart: Chart) => (
        <Link to={`/charts/${chart.id}`}>
          {chart.title === "" ? "Untitled Chart" : chart.title}
        </Link>
      )
    },
    {
      title: "Artist",
      key: "artist",
      render: (chart: Chart): string =>
        chart.artist === "" ? "-" : chart.artist
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy"
    },
    {
      title: "Date Created",
      key: "dateCreated",
      render: (chart: Chart): string => getDateFromString(chart.dateCreated),
      responsive: ["md"] as Breakpoint[]
    },
    {
      title: "Last Updated",
      key: "lastUpdated",
      render: (chart: Chart): string => getDateFromString(chart.lastUpdated),
      responsive: ["md"] as Breakpoint[]
    }
  ];
  const throttledTerm = useThrottle(term, 500);
  useEffect(() => {
    fetchChartsAPI(username, throttledTerm)
      .then(fetchedCharts => {
        setCharts(fetchedCharts);
        setIsFetching(false);
      })
      .catch(e => {
        setCharts([]);
        captureException(e);
      });
  }, [throttledTerm, username]);

  return (
    <>
      <Input.Search
        placeholder="Search Charts"
        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
          setTerm(e.target.value)
        }
        size="large"
        loading={isFetching}
      />
      <br />
      <br />
      <Table
        pagination={false}
        columns={columns}
        dataSource={charts}
        loading={isFetching}
        rowKey="id"
      />
    </>
  );
};

export default Charts;
