export const chordExtensionMap: { [key: string]: string } = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  F: "F",
  G: "G",
  7: "7",
  9: "9",
  11: "11",
  13: "13",
  "♭": "b",
  "♯": "#",
  Δ: "^",
  "-": "-",
  "+": "+",
  o: "o",
  ø: "h",
  sus: "sus",
  "/": "/"
};

export const sharpMap: { [key: string]: string } = {
  "": "",
  Cb: "C",
  C: "C#",
  "C#": "D",
  Db: "D",
  D: "D#",
  "D#": "E",
  Eb: "E",
  E: "E#",
  "E#": "F#",
  Fb: "F",
  F: "F#",
  "F#": "G",
  Gb: "G",
  G: "G#",
  "G#": "A",
  Ab: "A",
  A: "A#",
  "A#": "B",
  Bb: "B",
  B: "B#",
  "B#": "C#"
};

export const flatMap: { [key: string]: string } = {
  "": "",
  Cb: "Bb",
  C: "Cb",
  "C#": "C",
  Db: "C",
  D: "Db",
  "D#": "D",
  Eb: "D",
  E: "Eb",
  "E#": "E",
  Fb: "Eb",
  F: "Fb",
  "F#": "F",
  Gb: "F",
  G: "Gb",
  "G#": "G",
  Ab: "G",
  A: "Ab",
  "A#": "A",
  Bb: "A",
  B: "Bb",
  "B#": "B"
};
