import React from "react";
import { useSelector } from "react-redux";
import { Tooltip, Button, Badge } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlug,
  faEye,
  faCheckDouble,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { State } from "../../ducks";
import { green90, green30, orange90, orange30 } from "../misc/Color";

const border = "none";
const width = 42;

export const ConnectionStatusButton = () => {
  const wsStatus = useSelector((state: State) => state.ws.wsStatus);
  return (
    <Tooltip
      placement="top"
      title={wsStatus === "Open" ? "Connected" : "Connecting"}
    >
      <Button
        style={{
          backgroundColor: wsStatus === "Open" ? green90 : orange90,
          border,
          width
        }}
        icon={
          <FontAwesomeIcon
            icon={faPlug}
            style={{ color: wsStatus === "Open" ? green30 : orange30 }}
          />
        }
        loading={wsStatus !== "Open"}
      />
    </Tooltip>
  );
};

export const CurrentlyViewingStatusButton = () => {
  const wsStatus = useSelector((state: State) => state.ws.wsStatus);
  const currentlyViewing = useSelector(
    (state: State) => state.ws.currentlyViewing
  );

  return (
    <Tooltip placement="top" title={currentlyViewing.join()}>
      <Button
        style={{
          backgroundColor:
            wsStatus === "Open" && currentlyViewing.length > 0
              ? green90
              : orange90,
          border,
          width
        }}
        loading={wsStatus !== "Open"}
        icon={
          <Badge
            size="small"
            count={currentlyViewing.length}
            color={currentlyViewing.length > 0 ? green30 : orange30}
            offset={[3, 3]}
            status="success"
          >
            <FontAwesomeIcon
              icon={faEye}
              style={{
                color: currentlyViewing.length > 0 ? green30 : orange30
              }}
            />
          </Badge>
        }
      />
    </Tooltip>
  );
};

export const AckStatusButton = () => {
  const wsStatus = useSelector((state: State) => state.ws.wsStatus);
  const upToDate = useSelector(
    (state: State) => Object.keys(state.ws.ack).length === 0
  );
  const upToDate2 = useSelector(
    (state: State) => Object.keys(state.ws.ack2).length === 0
  );

  return (
    <Tooltip
      placement="top"
      title={
        upToDate && upToDate2
          ? "Up To Date"
          : upToDate
            ? "Update Received"
            : "Sending Update"
      }
    >
      <Button
        style={{
          backgroundColor:
            wsStatus === "Open" && upToDate && upToDate2 ? green90 : orange90,
          border,
          width
        }}
        icon={
          wsStatus === "Open" && upToDate && upToDate2 ? (
            <FontAwesomeIcon icon={faCheckDouble} style={{ color: green30 }} />
          ) : (
            <FontAwesomeIcon icon={faCheck} style={{ color: orange30 }} />
          )
        }
        loading={wsStatus !== "Open" || !upToDate}
      ></Button>
    </Tooltip>
  );
};
