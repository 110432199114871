import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Table } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { getDateFromString } from "../../util";
import { captureException } from "../../util/sentry";
import { fetchYourChartsAPI } from "../../api/main";
import { Chart } from "../../types";

const YourCharts = () => {
  const [charts, setCharts] = useState<Array<Chart>>([]);
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    fetchYourChartsAPI()
      .then(charts => {
        setCharts(charts);
        setIsFetching(false);
      })
      .catch(e => {
        setCharts([]);
        setIsFetching(false);
        captureException(e);
      });
  }, [setCharts, setIsFetching]);
  const columns = [
    {
      title: "Title",
      key: "title",
      render: (chart: Chart) => (
        <Link to={`/charts/${chart.id}`}>
          {chart.title === "" ? "Untitled Chart" : chart.title}
        </Link>
      )
    },
    {
      title: "Artist",
      key: "artist",
      render: (chart: Chart): string =>
        chart.artist === "" ? "-" : chart.artist
    },
    {
      title: "Date Created",
      key: "dateCreated",
      render: (chart: Chart): string => getDateFromString(chart.dateCreated)
    },
    {
      title: "Last Updated",
      key: "lastUpdated",
      render: (chart: Chart): string => getDateFromString(chart.lastUpdated),
      responsive: ["md"] as Breakpoint[]
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      responsive: ["md"] as Breakpoint[]
    }
  ];

  return (
    <>
      <Typography.Title>Your Charts</Typography.Title>
      <Table
        pagination={false}
        columns={columns}
        dataSource={charts}
        loading={isFetching}
        rowKey="id"
      />
    </>
  );
};

export default YourCharts;
