import { useNavigate } from "react-router-dom";
import { Button, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  ConnectionStatusButton,
  CurrentlyViewingStatusButton,
  AckStatusButton
} from "./WsStatusButtons";

const WsPageHeader = ({
  dropdownItems,
  isCollaborator
}: {
  dropdownItems: MenuProps['items'];
  isCollaborator: boolean;
}) => {
  const navigate = useNavigate();
  const extraStatuses = [
    <ConnectionStatusButton key="s1" />,
    <CurrentlyViewingStatusButton key="s2" />,
    <AckStatusButton key="s3" />
  ];
  const extraDropdown = (
    <Dropdown
      key="e1"
      trigger={["click"]}
      arrow
      menu={{ items: dropdownItems }}
      placement="bottomRight"
    >
      <Button
        icon={<FontAwesomeIcon icon={faEllipsisV} />}
        style={{ border: "none" }}
      />
    </Dropdown>
  );

  return (
    <PageHeader
      onBack={(): void => navigate(-1)}
      extra={
        isCollaborator ? [...extraStatuses, extraDropdown] : [extraDropdown]
      }
      style={{ borderBottom: "2px solid whitesmoke", padding: 8 }}
      backIcon={
        <Button
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          style={{ border: "none" }}
        />
      }
    />
  );
};

export default WsPageHeader;
