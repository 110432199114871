import { useDispatch } from "react-redux";
import ModalSpan from "../../common/ModalSpan";
import { updateBarField } from "../../../ducks/chart/actions";

export type Props = {
  field: "comment" | "section";
  value: string;
  barID: string; // TODO: pass barID into dispatch
};

const AnnotationTextEditable = ({
  field,
  value
}: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalSpan
      className={`tag annot ${field}`}
      title="Bar Editor"
      field={field}
      value={value}
      onOk={(v: string): void => {
        dispatch(updateBarField(field, v));
      }}
    />
  );
};

export default AnnotationTextEditable;
