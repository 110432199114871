import { request, parseJsonResponse } from "./request";
import { Chart } from "../types";

const fetchChartAPI = (chartID: string): Promise<Chart> =>
  request(`/api/charts/${chartID}`).then(parseJsonResponse);

const cloneChartAPI = (chartID: string): Promise<{ chartID: string }> =>
  request("/api/clonechart", {
    method: "post",
    body: { chartID },
    auth: true
  }).then(parseJsonResponse);

const addChartAPI = (): Promise<{ chartID: string }> =>
  request("/api/addchart", {
    method: "post",
    auth: true
  }).then(parseJsonResponse);

const deleteChartAPI = (chartID: string): Promise<Response> =>
  request("/api/deletechart", {
    method: "post",
    body: { chartID },
    auth: true
  });

export { fetchChartAPI, cloneChartAPI, addChartAPI, deleteChartAPI };
