// All icons from https://www.shareicon.net/pack/musical-symbols-and-annotations
export const StartRepeatNarrow = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 125 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="0" width="30" height="270" />
      <rect x="50" width="15" height="270" />
      <circle cx="110" cy="110" r="15" />
      <circle cx="110" cy="170" r="15" />
    </g>
  </svg>
);

export const StartRepeatSquare = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 270 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="0" width="30" height="270" />
      <rect x="50" width="15" height="270" />
      <circle cx="110" cy="110" r="15" />
      <circle cx="110" cy="170" r="15" />
    </g>
  </svg>
);

export const EndRepeatNarrow = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 125 270"
    xmlSpace="preserve"
  >
    <g>
      <circle cx="15" cy="110" r="15" />
      <circle cx="15" cy="170" r="15" />
      <rect x="60" width="15" height="270" />
      <rect x="95" width="30" height="270" />
    </g>
  </svg>
);

export const EndRepeatSquare = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 270 270"
    xmlSpace="preserve"
  >
    <g>
      <circle cx="160" cy="110" r="15" />
      <circle cx="160" cy="170" r="15" />
      <rect x="205" width="15" height="270" />
      <rect x="240" width="30" height="270" />
    </g>
  </svg>
);

export const StartDoubleNarrow = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 125 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="0" width="15" height="270" />
      <rect x="45" width="15" height="270" />
    </g>
  </svg>
);

export const StartDoubleSquare = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 270 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="0" width="15" height="270" />
      <rect x="45" width="15" height="270" />
    </g>
  </svg>
);

export const EndDoubleNarrow = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 125 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="65" width="15" height="270" />
      <rect x="110" width="15" height="270" />
    </g>
  </svg>
);

export const EndDoubleSquare = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 270 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="210" width="15" height="270" />
      <rect x="255" width="15" height="270" />
    </g>
  </svg>
);

export const FinalNarrow = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 125 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="50" width="15" height="270" />
      <rect x="95" width="30" height="270" />
    </g>
  </svg>
);

export const FinalSquare = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 270 270"
    xmlSpace="preserve"
  >
    <g>
      <rect x="195" width="15" height="270" />
      <rect x="240" width="30" height="270" />
    </g>
  </svg>
);
