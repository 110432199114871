import { all, ForkEffect, AllEffect } from "redux-saga/effects";
import { combineReducers, Reducer } from "redux";

import authSagas from "./auth/sagas";
import chartSagas from "./chart/sagas";
import playlistSagas from "./playlist/sagas";

import authReducers, { State as AuthState } from "./auth/reducers";
import chartReducers, { State as ChartState } from "./chart/reducers";
import playlistReducers, { State as PlaylistState } from "./playlist/reducers";
import wsReducers, { State as WsState } from "./ws/reducers";

export function* rootSaga(): Generator<AllEffect<ForkEffect<any>>> {
  yield all([...authSagas, ...chartSagas, ...playlistSagas]);
}

export interface State {
  auth: AuthState;
  chart: ChartState;
  playlist: PlaylistState;
  ws: WsState;
}

export const rootReducer = (): Reducer<State> =>
  combineReducers({
    auth: authReducers,
    chart: chartReducers,
    playlist: playlistReducers,
    ws: wsReducers,
  });
