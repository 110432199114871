import {
  updateChartField,
  addBar,
  deleteBar,
  updateBarField,
  updateChord,
  deleteBarField,
  addCollaborators,
  transpose as transposeAction
} from "./actions";
import { transpose } from "../../util/chart";
import { Bar, ToggleableBarField } from "../../types";
import { State as ChartState } from "../../ducks/chart/reducers";
import * as types from "./types";

export const wsAddBar = (state: ChartState): types.AddBarAction | undefined => {
  // if none selected, insert empty 4/4 bar at the starting position
  if (state.currentBarID === null) {
    return addBar(0, Array(4).fill(""), 1, 4);
  }
  const { chartData, currentBarID } = state;
  const barFocus = chartData.filter(x => x.id === currentBarID)[0];
  // if barFocus is found, add an empty bar (with the same numerator /
  // denominator / bar width) one position later
  if (barFocus) {
    const insertAt = chartData.findIndex(x => x.id === currentBarID) + 1;
    const barData = Array(barFocus.barData.length).fill("");
    const { barWidth, denominator } = barFocus;
    return addBar(insertAt, barData, barWidth, denominator);
  }
  // if barFocus cannot be found (for some reason), do nothing
  return undefined;
};

export const wsDeleteBar = (
  state: ChartState
): types.DeleteBarAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    return deleteBar(barFocus.id);
  }
  return undefined;
};

export const wsIncreaseBarWidth = (
  state: ChartState
): types.UpdateBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const width = barFocus.barWidth;
    // increase by one if width is 1/2/3
    if (width >= 1 && width <= 3) {
      return updateBarField(barFocus.id, "barWidth", width + 1);
    }
  }
  return undefined;
};

export const wsDecreaseBarWidth = (
  state: ChartState
): types.UpdateBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const width = barFocus.barWidth;
    // decrease by one if width is 2/3/4
    if (width >= 2 && width <= 4) {
      return updateBarField(barFocus.id, "barWidth", width - 1);
    }
  }
  return undefined;
};

export const wsUpdateNumerator = (
  state: ChartState,
  params: { n: number }
): types.UpdateBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const { barData } = barFocus;
    const { n } = params;
    // if numerator is larger, push empty chords to bar
    if (barData.length < n) {
      const value = barData.slice(0);
      const diff = n - value.length;
      for (let i = 0; i < diff; i += 1) {
        value.push("");
      }
      return updateBarField(barFocus.id, "barData", value);
      // if numerator is smaller, remove last few chords in bar
    }
    if (barData.length > n) {
      const value = barData.slice(0, n);
      return updateBarField(barFocus.id, "barData", value);
    }
  }
  return undefined;
};

export const wsUpdateDenominator = (
  state: ChartState,
  params: { d: number }
): types.UpdateBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const { denominator } = barFocus;
    const { d } = params;
    // send only if denominator is not the same
    if (denominator !== d) {
      return updateBarField(barFocus.id, "denominator", d);
    }
  }
  return undefined;
};

export const wsUpdateBarField = (
  state: ChartState,
  params: { field: string; value: any }
): types.UpdateBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const { field, value } = params;
    return updateBarField(barFocus.id, field, value);
  }
  return undefined;
};

export const wsDeleteBarField = (
  state: ChartState,
  params: { field: string }
): types.DeleteBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const { field } = params;
    return deleteBarField(barFocus.id, field);
  }
  return undefined;
};

export const wsToggleBarField = (
  state: ChartState,
  params: { field: ToggleableBarField }
): types.DeleteBarFieldAction | types.UpdateBarFieldAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  if (barFocus) {
    const { field } = params;
    const barID = barFocus.id;
    // if key currently exists, delete
    if (typeof barFocus[field] === "string") {
      return deleteBarField(barID, field);
    }
    // otherwise add key with empty string
    return updateBarField(barID, field, "");
  }
  return undefined;
};

export const wsUpdateChord = (
  state: ChartState
): types.UpdateChordAction | undefined => {
  const barFocus = state.chartData.filter(x => x.id === state.currentBarID)[0];
  const position = state.currentChordPosition;
  if (barFocus && position) {
    const barID = barFocus.id;
    const chord = state.editingChord;
    return updateChord(barID, position, chord);
  }
  return undefined;
};

export const wsUpdateChartField = (params: {
  field: string;
  value: any;
}): types.UpdateChartFieldAction | undefined => {
  const { field, value } = params;
  return updateChartField(field, value);
};

export const wsTranspose = (state: ChartState): types.TransposeAction => {
  const { transposeSemitones, chartData } = state;
  const bars = transpose(chartData, transposeSemitones).map((o: Bar): {
    id: string;
    barData: Array<string>;
  } => ({ id: o.id, barData: o.barData }));
  return transposeAction(bars);
};

export const wsAddCollaborators = (
  params: any
): types.AddCollaboratorsAction => {
  const { collaborators } = params;
  return addCollaborators(collaborators);
};
