import { useSelector } from "react-redux";
import Header from "../chartDetail/Header";
import {
  setShowTS,
  chunk,
  hasTop,
  hasBtm,
  transpose
} from "../../../util/chart";
import Bar from "./Bar";
import { State } from "../../../ducks";

const EditableChart = () => {
  const chartData = useSelector((state: State) =>
    state.chart.isTransposing
      ? transpose(state.chart.chartData, state.chart.transposeSemitones)
      : state.chart.chartData
  );
  const barsPerRow = useSelector((state: State) => state.chart.barsPerRow);

  return (
    <div className="chart-nottoolbar">
      <div className="chart-paper">
        <Header editable />
        {chunk(setShowTS(chartData), barsPerRow).map(barChunk => (
          <div
            className={`chart-row${hasTop(barChunk) ? " has-top" : ""}${
              hasBtm(barChunk) ? " has-btm" : ""
            }`}
            key={barChunk[0].id}
          >
            {barChunk.map(barData => (
              <Bar key={barData.id} barData={barData} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditableChart;
