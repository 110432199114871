import * as Sentry from "@sentry/browser";
import { message } from "antd";
import { getUsername } from ".";

const notifyError = (): void => {
  message.error("Something seems wrong...");
};

export const componentCaptureException = (
  error: Error,
  errorInfo: React.ErrorInfo
): void => {
  Sentry.withScope(scope => {
    scope.setExtra("error.info", errorInfo);
    Sentry.captureException(error);
  });
  notifyError();
};

export const captureException = (error: any): void => {
  Sentry.captureException(error);
  notifyError();
};

export const setUser = ({ username }: { username: string }): void => {
  Sentry.configureScope(scope => {
    scope.setUser({ username });
  });
};

export const initSentry = (): void => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV ? "production" : "staging"
  });
  const username = getUsername();
  if (username) setUser({ username });
};
