import { useSelector } from "react-redux";
import HeaderTextEditable from "./HeaderTextEditable";
import HeaderTextUneditable from "./HeaderTextUneditable";
import { State } from "../../../ducks";

export interface Props {
  editable?: boolean;
}

export const Header = ({ editable }: Props) => {
  const HeaderText = editable ? HeaderTextEditable : HeaderTextUneditable;
  const title = useSelector((state: State) => state.chart.title);
  const style = useSelector((state: State) => state.chart.style);
  const artist = useSelector((state: State) => state.chart.artist);
  return (
    <div className="chart-header">
      <div className="chart-title-div">
        <HeaderText field="title" value={title} />
      </div>
      <div className="chart-subtitle-div">
        <HeaderText field="style" value={style} />
        <HeaderText field="artist" value={artist} />
      </div>
    </div>
  );
};

Header.defaultProps = {
  editable: false
};

export default Header;
