import { useEffect, useRef, useState } from "react";

// see https://usehooks.com/useDebounce/
export const useDebounce = (value: any, delay: number): any => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

// see https://github.com/bhaskarGyan/use-throttle/blob/master/src/index.js
export const useThrottle = (value: any, limit: number): any => {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastRan = useRef(Date.now());
  useEffect(() => {
    const handler = setTimeout(() => {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value);
        lastRan.current = Date.now();
      }
    }, limit - (Date.now() - lastRan.current));
    return (): void => {
      clearTimeout(handler);
    };
  }, [value, limit]);

  return throttledValue;
};
