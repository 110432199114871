import type { MenuProps } from "antd";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import EditableChart from "./editable/EditableChart";
import EditableToolbar from "./editable/EditableToolbar";
import UneditableChart from "./uneditable/UneditableChart";
import WsPageHeader from "../common/WsPageHeader";
import EditCollaboratorsModal from "../common/EditCollaboratorsModal";
import {
  fetchChartRequested,
  cloneChartRequested,
  deleteChartRequested,
  addCollaborators
} from "../../ducks/chart/actions";
import { reinit } from "../../ducks/common/actions";
import { wsDisconnect } from "../../ducks/ws/actions";
import { State } from "../../ducks";

export const Chart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isFetching = useSelector((state: State) => state.chart.isFetching);
  const isCollaborator = useSelector(
    (state: State) =>
      state.auth.username === state.chart.createdBy ||
      state.chart.collaborators.includes(state.auth.username)
  );
  const createdBy = useSelector((state: State) => state.chart.createdBy);
  const isOwner = useSelector(
    (state: State) => state.chart.createdBy === state.auth.username
  );
  const existingCollaborators = useSelector(
    (state: State) => state.chart.collaborators
  );
  const loggedIn = useSelector((state: State) => state.auth.username !== "");
  const [isEditCollaboratorsModalOpen, setIsEditCollaboratorsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchChartRequested(id!));
    return () => {
      dispatch(wsDisconnect());
      dispatch(reinit());
    };
  }, [id, dispatch]);

  const dropdownItems: MenuProps['items'] = [
    {
      label: 'Edit Collaborators',
      key: 'editcollaborators',
      disabled: !isCollaborator,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      onClick: () => setIsEditCollaboratorsModalOpen(true),
    },
    {
      label: 'Clone Chart',
      key: 'clone',
      disabled: !loggedIn || isCollaborator,
      icon: <FontAwesomeIcon icon={faClone} />,
      onClick: () => dispatch(cloneChartRequested(id!, navigate)),
    },
    {
      label: 'Delete Chart',
      key: 'delete',
      disabled: !isOwner,
      icon: <FontAwesomeIcon icon={faTrash} />,
      onClick: () => {
        Modal.confirm({
          title: "Confirm Deletion",
          content: "Are you sure you want to delete?",
          onOk: () => dispatch(deleteChartRequested(id!, navigate)),
        })
      },
    }
  ]

  return (
    <>
      <WsPageHeader
        isCollaborator={isCollaborator}
        dropdownItems={dropdownItems}
      />
      <EditCollaboratorsModal
        createdBy={createdBy}
        existingCollaborators={existingCollaborators}
        onAdd={(newCollaborators: Array<string>) =>
          dispatch(addCollaborators(newCollaborators))}
        isOpen={isEditCollaboratorsModalOpen}
        setIsOpen={setIsEditCollaboratorsModalOpen}
      />
      {!isFetching && (
        <div className="chart-container">
          {isCollaborator ? (
            <>
              <EditableChart />
              <EditableToolbar />
            </>
          ) : (
            <UneditableChart />
          )}
        </div>
      )}
    </>
  );
};

export default Chart;
