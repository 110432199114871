import { useSelector } from "react-redux";
import { SortableContainer } from "react-sortable-hoc";
import PlaylistItem from "./PlaylistItem";
import { State } from "../../ducks";

const PlaylistList = () => {
  const playlistData = useSelector(
    (state: State) => state.playlist.playlistData
  );
  const playlistDataMetadata = useSelector(
    (state: State) => state.playlist.playlistDataMetadata
  );
  return (
    <ul style={{ padding: 0 }}>
      {playlistData.map((id, index) => (
        <PlaylistItem
          key={id}
          data={
            playlistDataMetadata && playlistDataMetadata[id]
              ? playlistDataMetadata[id]
              : { id, title: "", artist: "" }
          }
          index={index}
          pressDelay={200}
        />
      ))}
    </ul>
  );
};

export default SortableContainer(PlaylistList);
