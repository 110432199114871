import TimeBarSvg from "../../svg/timebar";

type TimeBarProps = {
  n: number;
};

const TimeBar = ({ n }: TimeBarProps) => (
  <div className="annot timebar">
    <TimeBarSvg n={n} />
  </div>
);

export default TimeBar;
