import { request, parseJsonResponse } from "./request";
import { Chart, Playlist } from "../types";

const refreshTokenAPI = (): Promise<{ jwt: string }> =>
  request("/api/token", {
    method: "post",
    auth: true
  }).then(parseJsonResponse);

const loginAPI = (
  usernameOrEmail: string,
  password: string
): Promise<{ jwt: string }> =>
  request("/api/login", {
    method: "post",
    body: {
      usernameOrEmail,
      password
    }
  }).then(parseJsonResponse);

const initResetPasswordAPI = (email: string): Promise<{ message: string }> =>
  request("/api/resetpassword/init", {
    method: "post",
    body: {
      email
    }
  }).then(parseJsonResponse);

const resetPasswordAPI = (
  email: string,
  token: string,
  password: string
): Promise<{ message: string }> =>
  request("/api/resetpassword", {
    method: "post",
    body: {
      email,
      token,
      password
    }
  }).then(parseJsonResponse);

const signupAPI = (
  username: string,
  email: string,
  password: string,
  grecaptcha: string
): Promise<{ message: string }> =>
  request("/api/signup", {
    method: "post",
    body: {
      username,
      email,
      password,
      grecaptcha
    }
  }).then(parseJsonResponse);

const activateAccountAPI = (
  username: string,
  token: string
): Promise<{ jwt: string }> =>
  request("/api/activate", {
    method: "post",
    body: {
      username,
      token
    }
  }).then(parseJsonResponse);

const fetchChartsAPI = (
  username: string,
  term: string
): Promise<Array<Chart>> =>
  request(`/api/charts?username=${username}&term=${term}`).then(
    parseJsonResponse
  );

const fetchYourChartsAPI = (): Promise<Array<Chart>> =>
  request("/api/yourcharts", {
    auth: true
  }).then(parseJsonResponse);

const fetchYourPlaylistsAPI = (): Promise<Array<Playlist>> =>
  request("/api/yourplaylists", {
    auth: true
  }).then(parseJsonResponse);

const fetchUsersAPI = (
  exclude: Array<string>,
  term: string
): Promise<{ suggestions: Array<string> }> =>
  request(
    `/api/users?${exclude.map(x => `exclude=${x}`).join("&")}&term=${term}`
  ).then(parseJsonResponse);

export {
  refreshTokenAPI,
  loginAPI,
  initResetPasswordAPI,
  resetPasswordAPI,
  signupAPI,
  activateAccountAPI,
  fetchChartsAPI,
  fetchYourChartsAPI,
  fetchYourPlaylistsAPI,
  fetchUsersAPI
};
