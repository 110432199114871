import { PlaylistItem } from "../../types";
import { REINIT, ReinitAction } from "../common/actions";
import * as constants from "./constants";
import * as types from "./types";

export interface State {
  playlistData: Array<string>;
  playlistDataMetadata: { [id: string]: PlaylistItem };
  title: string;
  collaborators: Array<string>;
  createdBy: string;
  currentChartID: string;
  playlistID?: string;
}

const initialState: State = {
  playlistData: [],
  playlistDataMetadata: {},
  title: "",
  collaborators: [],
  createdBy: "",
  currentChartID: ""
};

const fetchPlaylistRequested = (
  state: State,
  action: types.FetchPlaylistRequestedAction
): State => {
  const { playlistID } = action;
  return {
    ...state,
    playlistID
  };
};

const fetchPlaylistSucceeded = (
  state: State,
  action: types.FetchPlaylistSucceededActon
): State => {
  const {
    title,
    playlistData,
    playlistDataMetadata,
    collaborators,
    createdBy
  } = action.playlist;
  return {
    ...state,
    title,
    playlistData,
    playlistDataMetadata,
    collaborators,
    createdBy
  };
};

const fetchChartRequested = (
  state: State,
  action: types.FetchChartRequestedAction
): State => {
  const { chartID } = action;
  return {
    ...state,
    currentChartID: chartID
  };
};

const recvPlaylist = (
  state: State,
  action: types.RecvPlaylistAction
): State => {
  const {
    title,
    playlistData,
    playlistDataMetadata,
    collaborators,
    createdBy
  } = action.playlist;
  return {
    ...state,
    title,
    playlistData,
    playlistDataMetadata,
    collaborators,
    createdBy
  };
};

const deleteChart = (state: State, action: types.DeleteChartAction): State => {
  const playlistData = state.playlistData.filter(
    chartID => chartID !== action.chartID
  );
  return {
    ...state,
    playlistData
  };
};

const reorderCharts = (
  state: State,
  action: types.ReorderChartsAction
): State => {
  const { playlistData } = action;
  return {
    ...state,
    playlistData
  };
};

const reducer = (
  state: State = initialState,
  action:
    | ReinitAction
    | types.FetchPlaylistRequestedAction
    | types.FetchPlaylistSucceededActon
    | types.FetchChartRequestedAction
    | types.RecvPlaylistAction
    | types.DeleteChartAction
    | types.ReorderChartsAction
): State => {
  switch (action.type) {
    case REINIT: {
      return initialState;
    }
    case constants.FETCH_PLAYLIST_REQUESTED: {
      return fetchPlaylistRequested(state, action);
    }
    case constants.FETCH_PLAYLIST_SUCCEEDED: {
      return fetchPlaylistSucceeded(state, action);
    }
    case constants.FETCH_CHART_REQUESTED: {
      return fetchChartRequested(state, action);
    }
    case constants.RECV_PLAYLIST: {
      return recvPlaylist(state, action);
    }
    case constants.DELETE_CHART: {
      return deleteChart(state, action);
    }
    case constants.REORDER_CHARTS: {
      return reorderCharts(state, action);
    }
    default:
      return state;
  }
};

export default reducer;
