import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { addCharts } from "../../ducks/playlist/actions";
import { fetchYourChartsAPI } from "../../api/main";
import { State } from "../../ducks";
import { PlaylistItem } from "../../types";
import { captureException } from "../../util/sentry";

const AddChartButton = () => {
  const dispatch = useDispatch();
  const playlistData = useSelector(
    (state: State) => state.playlist.playlistData
  );
  const [active, setActive] = useState(false);
  const [yourCharts, setYourCharts] = useState<Array<PlaylistItem>>([]);
  const [selection, setSelection] = useState<Array<string>>([]);

  // fetch charts that have not been added whenever modal turns active
  useEffect(() => {
    if (active) {
      fetchYourChartsAPI()
        .then(response => {
          const currentChartIDs = playlistData;
          const allChartIDs = response.map(c => c.id);
          const filteredChartIDs = allChartIDs.filter(
            c => !currentChartIDs.includes(c)
          );
          const yourCharts = response.filter(c =>
            filteredChartIDs.includes(c.id)
          );
          setYourCharts(yourCharts);
        })
        .catch(e => captureException(e));
    }
  }, [active, playlistData]);

  const addChartsOnClick = (): void => {
    // add chart to playlist only if it is not already included
    const currentChartIDs = playlistData;
    const notCurrentChartIDs = selection.filter(
      c => !currentChartIDs.includes(c)
    );
    if (notCurrentChartIDs.length > 0) {
      const playlistData = currentChartIDs.concat(notCurrentChartIDs);
      dispatch(addCharts(playlistData));
    }
    setActive(!active);
  };

  return (
    <div>
      <Button
        onClick={(): void => setActive(true)}
        icon={<FontAwesomeIcon icon={faPlusCircle} />}
      >
        Add Chart
      </Button>
      <Modal
        open={active}
        okText="Add Charts"
        onOk={addChartsOnClick}
        onCancel={(): void => setActive(false)}
        title="Add Charts"
      >
        <Select
          style={{ width: "100%" }}
          mode="multiple"
          onChange={(a: string[]): void => setSelection(a)}
          placeholder="select charts"
        >
          {yourCharts.map(chart => (
            <Select.Option value={chart.id} key={chart.id}>
              {chart.title ? chart.title : "Untitled Chart"} (
              {chart.artist ? chart.artist : "Unknown Artist"})
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default AddChartButton;
