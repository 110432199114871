import { NavigateFunction } from "react-router-dom";
import { Playlist } from "../../types";
import * as constants from "./constants";
import * as types from "./types";

export const fetchPlaylistRequested = (
  playlistID: string
): types.FetchPlaylistRequestedAction => ({
  type: constants.FETCH_PLAYLIST_REQUESTED,
  playlistID
});

export const fetchPlaylistSucceeded = (
  playlist: Playlist
): types.FetchPlaylistSucceededActon => ({
  type: constants.FETCH_PLAYLIST_SUCCEEDED,
  playlist
});

export const fetchPlaylistFailed = (): types.FetchPlaylistFailedAction => ({
  type: constants.FETCH_PLAYLIST_FAILED
});

export const fetchChartRequested = (
  chartID: string
): types.FetchChartRequestedAction => ({
  type: constants.FETCH_CHART_REQUESTED,
  chartID
}); // fetchChartSucceeded and fetchChartFailed handled by chart

export const addPlaylistRequested = (
  navigate: NavigateFunction,
): types.AddPlaylistRequestedAction => ({
  type: constants.ADD_PLAYLIST_REQUESTED,
  navigate,
});

export const addPlaylistSucceeded = (): types.AddPlaylistSucceededAction => ({
  type: constants.ADD_PLAYLIST_SUCCEEDED
});

export const addPlaylistFailed = (): types.AddPlaylistFailedAction => ({
  type: constants.ADD_PLAYLIST_FAILED
});

export const deletePlaylistRequested = (
  playlistID: string,
  navigate: NavigateFunction
): types.DeletePlaylistRequestedAction => ({
  type: constants.DELETE_PLAYLIST_REQUESTED,
  playlistID,
  navigate,
});

export const deletePlaylistSucceeded = (): types.DeletePlaylistSucceededAction => ({
  type: constants.DELETE_PLAYLIST_SUCCEEDED
});

export const deletePlaylistFailed = (): types.DeletePlaylistFailedAction => ({
  type: constants.DELETE_PLAYLIST_FAILED
});

export const updatePlaylistField = (
  field: string,
  value: string
): types.UpdatePlaylistFieldAction => ({
  type: constants.UPDATE_PLAYLIST_FIELD,
  field,
  value
});

export const addCharts = (chartIDs: Array<string>): types.AddChartsAction => ({
  type: constants.ADD_CHARTS,
  chartIDs
});

export const deleteChart = (chartID: string): types.DeleteChartAction => ({
  type: constants.DELETE_CHART,
  chartID
});

export const reorderCharts = (
  playlistData: Array<string>
): types.ReorderChartsAction => ({
  type: constants.REORDER_CHARTS,
  playlistData
});

export const addCollaborators = (
  collaborators: Array<string>
): types.AddCollaboratorsAction => ({
  type: constants.ADD_COLLABORATORS,
  collaborators
});

export const recvPlaylist = (playlist: Playlist): types.RecvPlaylistAction => ({
  type: constants.RECV_PLAYLIST,
  playlist
});
